import auth from "@/helpers/auth";
import {
  EventBus
} from "@/main";

export default {
  methods: {
    logout: function () {
      $("#preloader").fadeIn();
      auth.dispatch("logout").then(() => {
        this.$router.push("/entrar");
      });
    },
    redirectWithDelay: function (route) {
      var _self = this;

      setTimeout(function () {
        _self.$router.push(route);
      }, 1500);
    },
    checkIfLoggedIn: function () {
      const access_token = localStorage.getItem("access_token") || "";
      const created_at = parseInt(localStorage.getItem("created_at")) || "";
      const expires_in = parseInt(localStorage.getItem("expires_in")) || "";
      const now = new Date();
      if (
        auth.getters.isLoggedIn &&
        access_token &&
        created_at &&
        expires_in &&
        new Date(
          new Date(created_at).setSeconds(
            new Date(created_at).getSeconds() + expires_in
          )
        ) > now
      ) {
        return true;
      }

      return false;
    },
    getCountries() {
      if (this.countries == null || !this.countries.length) {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/nomenclator/country')
          .then(response => {
            //console.log(response.data.countries);
            return this.countries = response.data.countries;
          })
          .catch(error => {
            this.errors = [];

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              if (typeof this.errors === "undefined" || this.errors.length == 0) {
                EventBus.$emit("showErrors", {
                  msg: error.response.data.message
                });
              }

              console.log(error.response.data.message);
            }

            return [];
          });
      } else {
        return this.countries;
      }
    },
    getOccupations() {
      if (this.occupations == null || !this.occupations.length) {
        axios
            .get(process.env.VUE_APP_API_URL + '/api/v1/nomenclator/occupations')
            .then(response => {
              //console.log(response.data.countries);
              return this.occupations = response.data.occupations;
            })
            .catch(error => {
              this.errors = [];

              if (error.response != undefined) {
                this.errors = error.response.data.errors ?? [];

                if (typeof this.errors === "undefined" || this.errors.length == 0) {
                  EventBus.$emit("showErrors", {
                    msg: error.response.data.message
                  });
                }

                console.log(error.response.data.message);
              }

              return [];
            });
      } else {
        return this.occupations;
      }
    },
    formatCurrency: function (mount) {
      if (mount != "") {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        if (formatter.format(mount) == '$NaN')
          return '$0.00';

        return formatter.format(mount);
      }

      return mount;
    },
    _clone: function (obj) {
      if (typeof obj === "undefined") {
        return "";
      }
      return JSON.parse(JSON.stringify(obj));
    },
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    curUser() {
      return JSON.parse(auth.getters.curUser) || [];
    },
  }
}