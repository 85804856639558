<template>
  <!-- Home Banner -->
  <div>
    <section id="home" class="divider">
      <div class="container-fluid p-0">
        <!--- slider ---->
        <div class="carousel-fade">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="bg-slider"
                src="@/assets/img/home/slider-1.jpg"
                alt=""
              />
              <div class="carousel-caption">
                <h2 class="pb-4" v-t="'home.first_title'"></h2>
                <p>
                  {{ $t('home.first_description') }}
                  <br />
                  <br />
                  {{ $t('home.second_description') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--- /slider ---->
      </div>
      <!-- /Home Banner -->
    </section>
    <section class="section home-tile-section py-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 m-auto">
            <div class="section-header text-center mb-5">
              <a id="nosotros"></a>
              <h2 class="mb-4" v-t="'home.us'"></h2>
              <p class="sub-title" v-t="'home.solid_enterprise'"></p>
            </div>
            <div class="row pb-5">
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center about-card p-4">
                  <img
                    src="@/assets/img/home/finance-icon-03.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="about-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.our_mission_title'"></h3>
                      <p class="px-1" v-t="'home.our_mission_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center about-card p-4">
                  <img
                    src="@/assets/img/home/finance-icon-02.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="about-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.our_plan_title'"></h3>
                      <p class="px-1" v-t="'home.our_plan_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center about-card p-4">
                  <img
                    src="@/assets/img/home/finance-icon-01.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="about-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.our_vision_title'"></h3>
                      <p class="px-1" v-t="'home.our_vision_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-md-5">
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center feature-card">
                  <img
                    src="@/assets/img/home/finance-illustration-02.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="sub-title mt-4 font-weight-bold" v-t="'home.send_payments'"></div>
                  <div class="feature-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.send_payments_title'"></h3>
                      <p class="px-1" v-t="'home.send_payments_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center feature-card">
                  <img
                    src="@/assets/img/home/finance-illustration-03.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="sub-title mt-4 font-weight-bold" v-t="'home.support'"></div>
                  <div class="feature-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.24h7D'"></h3>
                      <p class="px-1" v-t="'home.support_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center feature-card">
                  <img
                    src="@/assets/img/home/finance-illustration-01.png"
                    alt
                    class="img-fluid mt-2"
                  />
                  <div class="sub-title mt-4 font-weight-bold" v-t="'home.receive_payments'"></div>
                  <div class="feature-card-content mt-4">
                    <div>
                      <h3 class="font-weight-bold" v-t="'home.receive_payments_title'"></h3>
                      <p class="px-1" v-t="'home.receive_payments_description'"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section services-section">
      <div class="container-fluid px-0">
        <div class="row mx-0">
          <img src="@/assets/img/home/divider.jpg" alt class="img-fluid" />
        </div>
        <div class="row section-container py-5 mx-0">
          <div class="col-md-10 mx-auto my-5">
            <div class="section-header text-center mb-5">
              <a id="servicios"></a>
              <h2 class="mb-4" v-t="'home.services'"></h2>
              <p class="sub-title" v-t="'home.services_description'"></p>
            </div>
            <div class="service-content">
              <ul class="row">
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_first_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_second_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_third_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_fourth_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_fifth_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_sixth_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_seventh_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_eighth_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_ninth_item') }}
                </li>
                <li class="list-group-item col-sm-6 pl-3 pl-md-5">
                  <i class="fas fa-check-circle"></i>
                  {{ $t('home.services_tenth_item') }}
                </li>
              </ul>
              <p class="sub-title" v-t="'home.services_last_description'"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <faqs-index
        darkBackground
        whiteHeaderText
        centerHeaderText
        containerBodyContent
    ></faqs-index>
<!--    <section class="section testimonials-section py-5">-->
<!--      <div class="container-fluid px-3">-->
<!--        <div class="row">-->
<!--          <div class="section-header w-100 text-center mb-5 mt-1">-->
<!--            <h2 class="text-uppercase" v-t="'home.reviews'"></h2>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row testimonials-content">-->
<!--          <div class="col-lg-2"></div>-->
<!--          <div class="col-lg-10 testimonials-items p-5">-->
<!--            <div class="row px-sm-2 px-lg-5 pt-md-2 ml-4">-->
<!--              <div class="col-md-4 pb-4 text-center">-->
<!--                <div class="pro-content">-->
<!--                  <h3 class="title font-weight-bold pb-2">-->
<!--                    <router-link to="/doctor/profile"-->
<!--                      >{{ $t('home.first_person') }}</router-link-->
<!--                    >-->
<!--                  </h3>-->
<!--                  <p v-t="'home.first_review'"></p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-4 pb-4 text-center">-->
<!--                <div class="pro-content">-->
<!--                  <h3 class="title font-weight-bold pb-2">-->
<!--                    <router-link to="/doctor/profile">{{ $t('home.second_person') }}</router-link>-->
<!--                  </h3>-->
<!--                  <p class="speciality" v-t="'home.second_review'"></p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-4 pb-4 text-center">-->
<!--                <div class="pro-content">-->
<!--                  <h3 class="title font-weight-bold pb-2">-->
<!--                    <router-link to="/doctor/profile">{{ $t('home.third_person') }}</router-link>-->
<!--                  </h3>-->
<!--                  <p class="speciality" v-t="'home.third_review'"></p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section class="section contact-section py-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 m-auto">
            <div class="section-header text-center pt-5 mb-5">
              <a id="contactenos"></a>
              <h2 class="mb-4" v-t="'home.contact_us'"></h2>
            </div>
            <div class="row pb-5">
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center contact-card p-5">
                  <a
                    href="https://pagosundolar.com"
                    class="d-block mx-5"
                  >
                    <img
                      src="@/assets/img/home/social-fb.png"
                      alt
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center contact-card p-5">
                  <a
                    href="https://www.instagram.com/pagosundolar/"
                    class="d-block mx-5"
                  >
                    <img
                      src="@/assets/img/home/social-ig.png"
                      alt
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mb-3 p-3">
                <div class="text-center contact-card p-5">
                  <a href="https://wa.me/17864802652" class="d-block mx-5">
                    <img
                      src="@/assets/img/home/social-wa.png"
                      alt
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 form-contact">
              <form class="w-100" @submit.prevent="sendMessage">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <input
                      v-model="contact.name"
                      type="text"
                      class="form-control"
                      :placeholder="$t('home.full_name')"
                    />
                    <small v-if="errors.name" class="text-danger">{{
                      errors.name[0]
                    }}</small>
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      v-lower-case
                      v-model="contact.email"
                      type="email"
                      class="form-control"
                      :placeholder="$t('home.email')"
                    />
                    <small v-if="errors.email" class="text-danger">{{
                      errors.email[0]
                    }}</small>
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    v-model="contact.message"
                    class="form-control"
                    rows="5"
                    :placeholder="$t('home.message')"
                  ></textarea>
                  <small v-if="errors.message" class="text-danger">{{
                    errors.message[0]
                  }}</small>
                </div>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">
                    {{ $t('home.send') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style src="@/assets/scss/frontend/home.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import FaqsIndex from './Faqs/Index';

export default {
  components: { FaqsIndex },
  data() {
    return {
      contact: {
        name: "",
        email: "",
        message: "",
      },
      errors: [],
    };
  },
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");
  },
  methods: {
    sendMessage(evt) {
      evt.preventDefault();

      this.errors = [];

      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/contact/send",
          this.contact
        )
        .then((response) => {
          this.contact = {
            name: "",
            email: "",
            message: "",
          };

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          
          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }
        });
    },
  },
};
</script>
