import Vue from 'vue'
import App from './App.vue'
import router from './router'
import auth from './helpers/auth'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

import './assets/scss/_preloader.scss'
import './assets/scss/_global.scss'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css';

import Vuetify from 'vuetify'
Vue.use(Vuetify)
Vue.use(vueFilterPrettyBytes)

import Blank from "./views/Blank.vue"
import Page from "./views/Page.vue"
import Wallet from "./views/Wallet.vue"
import Messages from "@/components/Messages"
import './registerServiceWorker'
import i18n from './i18n'

Vue.component("LayoutBlank", Blank)
Vue.component("LayoutPage", Page)
Vue.component("LayoutWallet", Wallet)
Vue.component("messages", Messages)

Vue.filter('lowerCase', function (value) {
  return _.isString(value) ? _.toLower(value) : value;
});

Vue.directive('lower-case', {
    bind(el, __, vNode) {
        el.emitInput = () => {
            if (vNode.componentInstance) {
                const valueLowerCase = Vue.options.filters.lowerCase(vNode.componentInstance.$el.getElementsByTagName('input')[0].value);
                vNode.componentInstance.$emit('input', valueLowerCase);
                return;
            }

            const valueLowerCase = Vue.options.filters.lowerCase(el.value);
            if (el.value !== valueLowerCase) {
                el.value = valueLowerCase;
                el.dispatchEvent(new Event('input'));
            }
        };

        el.addEventListener('input', () => {
            el.emitInput();
        });
    },
    unbind(el) {
        el.removeEventListener('input', el.emitInput());
    }
});

Vue.config.productionTip = false

export const EventBus = new Vue()

new Vue({
    router,
    auth,

    vuetify: new Vuetify({
      breakpoint: {
        mobileBreakpoint: 'xs'
      }
    }),

    i18n,
    render: h => h(App)
}).$mount('#app')
