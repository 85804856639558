<template>
  <div class="container-fluid">
    <div class="page-titles d-flex justify-content-between align-items-center">
      <h2 class="text-black font-w600 w-50">Transferencias</h2>
      <router-link
        class="btn btn-rounded btn-primary"
        :to="{ name: 'transferencia-new' }"
      >
        <span class="btn-icon-left text-primary"
        ><i class="fa fa-plus" aria-hidden="true"></i> </span
        >Nueva
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="table-transfers"
                class="table display responsive w-100"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Email</th>
                    <th>Monto</th>
                    <th>Descripción</th>
                    <th class="no-sort"></th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Email</th>
                    <th>Monto</th>
                    <th>Descripción</th>
                    <th class="no-sort"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup modal confirm cancel transfer -->
    <div class="text-center">
      <confirm-dialog
          ref="confirmCancelTransferDialog"
          header_title="Cancelar transferencia"
          @accept="onAcceptConfirmCancelTransfer"
      ></confirm-dialog>
    </div>

    <!-- Popup modal transfer details -->
    <details-dialog :details="transferSelected" ref="transferDetailsDialog">
      <template v-slot:details="transferSelected">
        <transfer-details :transfer="transferSelected.details"></transfer-details>
      </template>
    </details-dialog>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import ConfirmDialog from '../Shared/ConfirmDialog';
import DetailsDialog from '../Shared/DetailsDialog';
import TransferDetails from './Details';

export default {
  components: {
    ConfirmDialog,
    TransferDetails,
    DetailsDialog,
  },

  data() {
    return {
      transfers: [],
      tableFields: [
        "id",
        "date",
        "recipient.client.user.email",
        "amount_display",
        "comments"
      ],
      datatableOptions: {
        order: [[1, "desc"]],
        columnDefs: [
          {
            targets: 0,
            className: "dt-center",
          },
          {
            targets: 3,
            responsivePriority: 1,
            className: "dt-body-right",
          },
          {
            targets: "no-sort",
            orderable: false,
          },
        ],
      },
      showCancelTransferModal: false,
      transferIdSelected: null,
      transferSelected: null,
      errors: [],
    };
  },
  mixins: [global, table],
  mounted() {
    this.dataTable = this.initializeTable("#table-transfers", this.datatableOptions);

    this.getTransfers().finally(() => {
      this.generateTableWithData(this.transfers);

      let component = this;

      $("#table-transfers").on("click", ".action-cancel", async function () {
        $("#table-transfers tr.selected").removeClass("selected");
        $(this)
            .closest("tr")
            .addClass("selected");

        component.transferIdSelected = $(this).attr("attr_id");

        component.confirmCancelTransfer();
      });

      $("#table-transfers").on("click", ".action-details", async function () {
        $("#table-transfers tr.selected").removeClass("selected");
        $(this)
            .closest("tr")
            .addClass("selected");

        component.transferIdSelected = $(this).attr("attr_id");
        component.transferSelected = component.transfers.find((transfer) => transfer.id == component.transferIdSelected);

        component.$refs.transferDetailsDialog.open();
      });

      this.renderTable();
    });
  },
  methods: {
    getTransfers() {
      return axios
          .get(process.env.VUE_APP_API_URL + "/api/v1/transfers/list").then((response) => {
            this.transfers = response.data.transfers;
          })
          .catch((error) => {
            this.errors = [];

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              EventBus.$emit("showErrors", {
                msg: error.response.data.error,
              });
            }
          });
    },
    tableActionsByObject(transfer) {
      let actions = `<div class="table-action text-right">`;

      if (transfer.can_be_cancelled) {
        const cancelButtonClass = `btn btn-outline-warning btn-md btn-rounded m-1 action-cancel ${!transfer.can_be_cancelled ? 'v-btn--disabled' : ''}`;
        const cancelButtonText = 'Cancelar';
        const title = 'Click para cancelar';
        const attr = transfer.id;

        actions += `<a href="javascript:void(0);"
                    title="${title}"
                    attr_id="${attr}"
                    class="${cancelButtonClass}">
                    ${cancelButtonText}
                  </a>`;
      } else if (transfer.is_canceled) {
        const cancelButtonClass = `btn btn-outline-canceled btn-md btn-rounded v-btn--disabled m-1`;
        const cancelButtonText = 'Cancelado';

        actions += `<a href="javascript:void(0);"
                    class="${cancelButtonClass}">
                    ${cancelButtonText}
                  </a>`;
      }

      actions += `<a href="javascript:void(0);"
                    title="Ver detalles"
                    attr_id="${transfer.id}"
                    class="btn btn-outline-info btn-md btn-rounded m-1 action-details">
                    Detalles
                  </a>`;

      actions += "</div>";

      return actions;
    },

    async cancel() {
      try {
        const { data } = await axios.put(process.env.VUE_APP_API_URL + `/api/v1/transfers/cancel/${this.transferIdSelected}`);

        if (data.success) {
          EventBus.$emit("showInfo", {
            msg: data.message
          });
        }
        if (this.dataTable) {
          this.dataTable.destroy();
        }

        this.dataTable = this.initializeTable("#table-transfers", this.datatableOptions);

        await this.getTransfers().finally(() => {
          this.generateTableWithData(this.transfers);

          this.renderTable();
        });
      } catch ({ response: { data: { message } } }) {
        EventBus.$emit("showErrors", {
          msg: message
        });
      }
    },

    confirmCancelTransfer() {
      this.$refs.confirmCancelTransferDialog.open();
    },

    onAcceptConfirmCancelTransfer() {
      this.cancel();
    },
  },
};
</script>
