<template>
  <li class="nav-item dropdown notification_dropdown">
    <a
        class="nav-link ai-icon"
        href="javascript:void(0)"
        role="button"
        data-toggle="dropdown"
        @click="showAllNotifications">
      <v-badge :content="total"
               :value="total"
               color="blue"
               overlap>
        <v-icon
            color="black"
            large>mdi-bell-outline
        </v-icon>
      </v-badge>
    </a>
    <div v-show="hasPendingNotifications" class="dropdown-menu dropdown-menu-right">
      <div
          id="DZ_W_Notification1"
          class="widget-media dz-scroll p-3 height380">
        <ul class="timeline">
          <template v-for="notification in notifications">
            <notification-item
                :key="notification.id"
                :description="notification.data.description"
                :created-date="notification.created_at_formatted"
                @read="onRead"
                image="/img/avatar/1.jpg">
            </notification-item>
          </template>
        </ul>
      </div>
      <a class="all-notification"
         href="javascript:void(0)"
         @click="navigateToAllNotifications()">
        Ver todas.
        <i class="ti-arrow-right"></i>
      </a>
    </div>
  </li>
</template>

<script>
  import NotificationItem from './NotificationItem';
  import notification from '../../helpers/notification';

  export default {
    name: "Notification",
    mixins: [notification],

    components: {
      NotificationItem,
    },

    data() {
      return {
        notifications: [],
        total: 0,
        hasRecentlyNotifications:false,
      };
    },

    methods: {
      async getNotifications(filter) {
        const notificationUrl = process.env.VUE_APP_API_URL + `/api/v1/notifications/unread-list`;
        const {
          data: {
            notifications, total, hasRecentlyNotifications
          }
        } = await axios.post(notificationUrl, filter);

        this.hasRecentlyNotifications = hasRecentlyNotifications;
        this.notifications = notifications;
        this.total = total;
      },
      navigateToAllNotifications() {
        if (!this.isRouteNotificationList()) {
          this.$router.push('/notificaciones');
        }
      },
      onRead(id) {
        this.selectedNotification = this.getNotificationById(id, this.notifications);

        if (this.hasRouteToViewNotification(this.selectedNotification.type_name)) {
          this.navigateToViewNotification(this.getRouteByNotificationType(this.selectedNotification.type_name));
        }

        this.getNotifications();
      },
      showAllNotifications() {
        if (!this.hasPendingNotifications) {
          this.navigateToAllNotifications();
        }
      }
    },

    computed: {
      hasPendingNotifications() {
        return !!this.total;
      }
    },

    mounted() {
      this.getNotifications();
    }
  };
</script>

<style src="@/assets/scss/header.scss" lang="scss" scoped></style>
