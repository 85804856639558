<template>
  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1 mb-5">
          <div class="row">
            <div class="col-md-10 mx-auto my-5 text-center">
              <h2>Crea una cuenta en Pagos UnDolar, ¡es gratis!</h2>
              <hr />
              <p class="font-weight-bold text-muted">Puedes elegir entre estos dos tipos de cuenta:</p>
            </div>
          </div>
          <div class="row register-content align-items-center justify-content-center">
            <div class="col-md-6">
              <span class="register-person">
                <img src="@/assets/img/mobile-phone.jpg" class="img-fluid" alt="Personal" />
              </span>
              <div class="col">
                <h3 class="text-center mt-4">Personal</h3>
                <ul>
                  <li>Paga tus compras.</li>
                  <li>Compra en miles de comercios afiliados.</li>
                  <li>Paga con tu usuario y contraseña.</li>
                  <li>Sin compartir tu datos bancarios.</li>
                  <li>Recibe dinero de tus familiares.</li>
                </ul>
              </div>
              <div class="col ml-4">
                <router-link to="/registrar-usuario" class="btn btn-rounded btn-outline-primary">Regístrate</router-link>
              </div>
            </div>
            <div class="col-md-6">
              <span class="register-company">
                <img src="@/assets/img/entrepreneur.jpg" class="img-fluid" alt="Personal" />
              </span>
              <div class="col">
                <h3 class="text-center mt-4">Empresa</h3>
                <ul>
                  <li>Recibe pagos por tus productos y servicios.</li>
                  <li>Maneja tus fondos.</li>
                  <li>No se devalúan tus ingresos.</li>
                  <li>Dispones de tus fondos al momento de recibir el pago.</li>
                  <li style="visibility: hidden"></li>
                </ul>
              </div>
              <div class="col ml-4">
                <router-link to="/registrar-empresa" class="btn btn-rounded btn-outline-primary">Regístrate</router-link>
              </div>
            </div>
          </div>
          <!-- /Login Tab Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<script>
import global from "@/helpers/global";

export default {
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");
  }
};
</script>