<template>
  <div class="container-fluid">
    <div class="page-titles d-flex justify-content-between align-items-center">
      <h2 class="text-black font-w600 w-50">Notificaciones</h2>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                  id="table-notifications"
                  class="table display responsive w-100">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Fecha y hora</th>
                  <th>Descripción</th>
                  <th class="no-sort"></th>
                </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                <tr>
                  <th>Nombre</th>
                  <th>Fecha y hora</th>
                  <th>Descripción</th>
                  <th class="no-sort"></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import global from "@/helpers/global";
  import table from "@/helpers/table";
  import notification from "@/helpers/notification";

  export default {
    name: "NotificationList",
    mixins: [global, table, notification],

    data() {
      return {
        notifications: [],
        dataTable: null,
        tableFields: [
          'name',
          'created_at_formatted',
          'data.description'
        ],
        errors: [],
      };
    },

    methods: {
      async buildNotificationsDatatable() {
        await this.getNotifications();

        if (this.dataTable) {
          this.dataTable.destroy();
        }

        const options = {
          columnDefs: [
            {
              targets: 0,
              className: "dt-center",
            },
            {
              targets: 1,
              responsivePriority: 1,
            },
            {
              targets: "no-sort",
              orderable: false,
            },
          ],
        };

        this.dataTable = this.initializeTable("#table-notifications", options);

        this.generateTable();

        this.renderTable();
      },
      async getNotifications(filter) {
        const notificationUrl = process.env.VUE_APP_API_URL + `/api/v1/notifications`;
        const {
          data: {
            notifications
          }
        } = await axios.post(notificationUrl, filter);

        this.data = notifications;
      },
      tableActionsByObject(notification) {
        let actions = '<div class="table-action">';

        actions += `<a href="javascript:void(0);" title="Click para ver o marcar como leída" attr_id="${notification.id}" class="btn btn-sm bg-info-light action-view"><i class="far fa-eye"></i> Ver</a>`;

        actions += "</div>";

        return actions;
      }
    },

    mounted() {
      this.buildNotificationsDatatable();

      const component = this;

      $("#table-notifications").on("click", ".action-view", async function() {
        $("#table-notifications tr.selected").removeClass("selected");
        $(this)
            .closest("tr")
            .addClass("selected");

        const notificationId = $(this).attr("attr_id");

        component.selectedNotification = component.getNotificationById(notificationId);

        await component.read(notificationId);

        if (component.hasRouteToViewNotification(component.selectedNotification.type_name)) {
          component.navigateToViewNotification(component.getRouteByNotificationType(component.selectedNotification.type_name));

          return;
        }

        if (component.isRouteNotificationList()) {
          await component.buildNotificationsDatatable();
        }
      });
    }
  };
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>
