<template>
  <!-- Page Content -->
  <div class="content success-page-cont">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <!-- Success Card -->
          <div class="card success-card">
            <div class="card-body">
              <div class="success-cont">
                <i class="fas fa-check"></i>
                <h3>Contraseña cambiada</h3>
                <p>Su contraseña ha sido cambiada <strong>satisfactoriamente</strong>.</p>
                <router-link to="/entrar" class="btn btn-primary view-inv-btn">Entrar</router-link>
              </div>
            </div>
          </div>
          <!-- /Success Card -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<style src="@/assets/scss/frontend/home.scss" lang='scss' scoped></style>
<style src="@/assets/scss/frontend/content.scss" lang='scss' scoped></style>

<script>
import global from "@/helpers/global";

export default {
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");
  }
};
</script>