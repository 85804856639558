<template>
  <v-expansion-panels focusable>
    <slot name="faqs-items">
      <template v-for="faq in faqsItems">
        <faqs-item
            :question="faq"
            :key="faq.id"
            :lang_key="faq.lang_key"
        >
        </faqs-item>
      </template>
    </slot>
  </v-expansion-panels>
</template>

<script>
import global from "@/helpers/global";
import FaqsItem from './FaqsItem';

export default {
  name: "FaqsList",
  components: { FaqsItem },
  mixins: [global],
  props: {
    items: {
      type: Array,
      require: false,
    }
  },

  data() {
    return {
      faqsItems: this.items,
    };
  },

  methods: {
    setFaqsItems() {
      if (!this.items?.length) {
        this.faqsItems = [
          {
            id: 1,
            text:  this.$t('faq.first_question'),
            answer: this.$t('faq.first_answer'),
            lang_key: 'faq.first'
          },
          {
            id: 2,
            text:  this.$t('faq.second_question'),
            answer: this.$t('faq.second_answer'),
            lang_key: 'faq.second'
          },
          {
            id: 3,
            text:  this.$t('faq.third_question'),
            answer: this.$t('faq.third_answer'),
            lang_key: 'faq.third'
          },
          // {
          //   id: 4,
          //   text:  this.$t('faq.fourth_question'),
          //   answer: this.$t('faq.fourth_answer'),
          //   lang_key: 'faq.fourth'
          // },
          // {
          //   id: 5,
          //   text:  this.$t('faq.fifth_question'),
          //   answer: this.$t('faq.fifth_answer'),
          //   lang_key: 'faq.fifth'
          // },
          // {
          //   id: 6,
          //   text:  this.$t('faq.sixth_question'),
          //   answer: this.$t('faq.sixth_answer'),
          //   lang_key: 'faq.sixth'
          // }
        ];
      }
    }
  },

  mounted() {
    this.setFaqsItems();
  }
};
</script>
