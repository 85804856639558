<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
        persistent
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{ header_title }}
        </v-card-title>

        <div class="p-md-4 align-center">
          {{ confirm_message }}
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="onCancelConfirm"
          >
            {{ cancel_button_text }}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="onAcceptConfirm"
          >
            {{ accept_button_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "ConfirmDialog",

    props: {
      header_title: { type: String, default: 'Confirmar' },
      confirm_message: { type: String, default: '¿Seguro que desea realizar esta acción?' },
      accept_button_text: { type: String, default: 'Aceptar' },
      cancel_button_text: { type: String, default: 'Cancelar' },
    },

    data() {
      return {
        show: false,
      };
    },

    methods: {
      open() {
        this.show = true;
      },

      close() {
        this.show = false;
      },

      onAcceptConfirm() {
        this.close();

        this.$emit('accept');
      },

      onCancelConfirm() {
        this.close();

        this.$emit('cancel');
      }
    },
  };
</script>
