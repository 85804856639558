<template>
  <div class="container">
    <div class="row">
      <div class="col-12"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6 font-weight-bold">Detalles de la transacción</div>
          <div class="col-12 col-lg-6 col-md-6 text-left text-md-right text-sm-right text-lg-right">
            <div><span class="font-weight-bold">ID: </span>#{{ transaction.id }}</div>
            <div><span class="font-weight-bold">Referencia: </span>{{ transaction.reference }}</div>
            <div><span class="font-weight-bold">Fecha: </span>{{ transaction.date }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6 col-sm-6">
            <div>
              <span class="font-weight-bold">Email: </span>
              <span>{{ email }}</span>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6 col-sm-6">
            <span class="font-weight-bold h4">Monto: </span>
            <span class="font-weight-bold h4">{{ transaction.amount_display }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="font-weight-bold">Método de pago: </span>
            <span>{{ transaction.payment_type }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="font-weight-bold">Estado: </span>
            <span class="">{{ transaction.status.display_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionDetails",

  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  computed:{
    email() {
      if (this.transaction.is_internal && this.transaction.has_transfer) {
        if (this.transaction.is_incoming) {
          const personEmail = _.get(this.transaction.transfer.wallet, 'person.user.email', '');

          return _.get(this.transaction.transfer.wallet, 'company.user.email', personEmail);
        }
        const personEmail = _.get(this.transaction.transfer.wallet_to, 'person.user.email', '');

        return _.get(this.transaction.transfer.wallet_to, 'company.user.email', personEmail);
      }

      return '';
    }
  }
};
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>
