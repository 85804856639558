<template>
  <li @click="emitOnclick" title="Click para ver o marcar como leída">
    <div class="timeline-panel">
      <div class="media mr-2">
        <img
            alt=""
            width="50"
        />
      </div>
      <div class="media-body">
        <h6 class="mb-1">{{ description }} </h6>
        <small class="d-block">{{ createdDate }} </small>
      </div>
    </div>
  </li>
</template>

<script>
  import notification from "@/helpers/notification";

  export default {
    name: "NotificationItem",
    mixins: [notification],
    data() {
      return {
        assetsDirectory: '../../../assets/',
        img3: require('../../assets/img/avatar/1.jpg'),
      };
    },
    props: {
      description: String,
      createdDate: String,
      image: String,
    },
    methods: {
      async emitOnclick() {
        await this.read();

        this.$emit('read', this.$vnode.key);
      }
    }
  };
</script>

<style src="@/assets/scss/header.scss" lang="scss" scoped></style>

<style>
  li .timeline-panel {
    cursor: pointer;
  }

  li .timeline-panel :hover {
    color: rgba(33, 150, 243, 1);
  }
</style>
