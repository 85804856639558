<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">
        Retirar Saldo
        <div
          v-show="walletBalance != ''"
          class="flex-row-reverse float-right ml-md-auto align-self-md-end pl-1"
        >
          <span class="text-sm font-weight-bold">Saldo </span>
          <span v-if="walletBalance" class="badge light badge-light py-0">{{
            formatCurrency(walletBalance)
          }}</span>
          <span v-else class="badge light badge-light py-0">$0.00</span>
        </div>
      </h2>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <section-preloader />
                <form @submit.prevent="withdraw" method="POST">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="firstName"
                        >Moneda <span class="text-danger">*</span></label
                      >
                      <v-autocomplete
                        class="form-control"
                        :items="currencies"
                        flat
                        hide-details
                        hide-no-data
                        solo-inverted
                        item-text="acronym"
                        item-value="id"
                        v-model="withdrawalData.currency_id"
                        @change="processPayment()"
                      ></v-autocomplete>
                      <small v-if="errors.currency_id" class="text-danger">{{
                        errors.currency_id[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <label for="username"
                        >Monto <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          step="0.01"
                          min="0.01"
                          v-model="withdrawalData.amount"
                          @change="processPayment()"
                        />
                      </div>
                      <small v-if="errors.amount" class="text-danger">{{
                        errors.amount[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-0"
                        >Método de pago
                        <span class="text-danger">*</span></label
                      >
                      <div class="d-block my-3">
                        <div
                          v-for="(value, key) in withdrawal_ways"
                          v-bind:key="key"
                          class="custom-control custom-radio mb-2"
                        >
                          <input
                            name="paymentMethod"
                            :checked="
                              withdrawalData.withdrawal_way_id == value.id
                                ? 'checked'
                                : false
                            "
                            type="radio"
                            class="custom-control-input"
                          />
                          <label
                            @click="changePaymentMethod(value.id)"
                            class="custom-control-label"
                            for="paymentMethod"
                            >{{ value.name }}</label
                          >
                        </div>
                        <small
                          v-if="errors.withdrawal_way_id"
                          class="text-danger"
                          >{{ errors.withdrawal_way_id[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-row form-group-box"
                    v-if="
                      withdrawal_way &&
                        (payment_config.id || withdrawal_way.metadata.length)
                    "
                  >
                    <div
                      class="group-box-btn"
                      :class="
                        isEditing && !payment_config.id
                          ? 'group-box-btn-save'
                          : ''
                      "
                    >
                      <button
                        v-if="payment_config.id && !isEditing"
                        type="button"
                        class="edit"
                        aria-label="Editar"
                        @click="isEditing = true"
                      >
                        <span aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <path
                              d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                            />
                          </svg>
                          Editar
                        </span>
                      </button>
                      <button
                        v-if="isEditing"
                        type="button"
                        class="save"
                        aria-label="Guardar"
                        @click="
                          payment_config.id
                            ? updateConfigPayment()
                            : addConfigPayment()
                        "
                      >
                        <span aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"
                            />
                          </svg>
                          Guardar
                        </span>
                      </button>
                      <button
                        v-if="isEditing && payment_config.id"
                        type="button"
                        class="cancel ml-3"
                        aria-label="Cancelar"
                        @click="
                          cancelEditPayment(payment_config.withdrawal_way_id)
                        "
                      >
                        <span aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                            />
                          </svg>
                          Cancelar
                        </span>
                      </button>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-md-12 mb-3"
                        v-for="(field, key) in payment_config.metadata"
                        v-bind:key="key"
                      >
                        <label
                          >{{ field.label }}
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="hidden"
                          v-model="payment_config.metadata[key].metadata_id"
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model="payment_config.metadata[key].value"
                          :readonly="!isEditing"
                        />
                        <small v-if="errors[field.name]" class="text-danger">{{
                          errors[field.name][0][0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!errors.withdrawal_way_id && errors.payment_config_id"
                  >
                    <small class="text-danger">{{
                      errors.payment_config_id[0]
                    }}</small>
                  </div>
                  <div v-if="checkoutData">
                    <hr class="mb-4" />
                    <h4 class="fs-18 mb-3">Total a pagar</h4>
                    <ul class="list-group mb-3 pl-0">
                      <li
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h6 class="my-0">Monto a retirar</h6>
                          <small class="text-muted"
                            >Total a enviar al usuario</small
                          >
                        </div>
                        <span class="text-muted">{{
                          formatCurrency(withdrawalData.amount)
                        }}</span>
                      </li>
                      <li
                        v-if="checkoutData.withdrawal_way > 0"
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h6 class="my-0">Comisión método de pago</h6>
                          <small class="text-muted"
                            >Cobrada por la pasarela de pago</small
                          >
                        </div>
                        <span class="text-muted">{{
                          formatCurrency(checkoutData.withdrawal_way)
                        }}</span>
                      </li>
                      <li
                        v-if="checkoutData.system > 0"
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h6 class="my-0">Comisión PagosUnDolar</h6>
                          <small class="text-muted"
                            >Cobrada por el sistema</small
                          >
                        </div>
                        <span class="text-muted">{{
                          formatCurrency(checkoutData.system)
                        }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Total (USD)</span>
                        <strong>{{
                          formatCurrency(checkoutData.final_amount)
                        }}</strong>
                      </li>
                    </ul>
                  </div>
                  <hr class="mb-4" />
                  <div class="custom-control custom-checkbox mt-4 mb-2">
                    <input
                      v-model="withdrawalData.accept_terms"
                      type="checkbox"
                      class="custom-control-input"
                      id="same-address"
                    />
                    <label class="custom-control-label" for="same-address"
                      >Términos y Condiciones. Haga clic
                      <a
                        href="javascript:void();"
                        data-toggle="modal"
                        data-target="#termsModal"
                        >aquí</a
                      >
                      para ver.</label
                    >
                  </div>
                  <small
                    v-if="errors.accept_terms"
                    class="d-block text-danger"
                    >{{ errors.accept_terms[0] }}</small
                  >
                  <button
                    class="btn btn-primary btn-lg btn-block mt-8 col-md-6 mx-auto"
                    type="submit"
                  >
                    Enviar solicitud
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

import SectionPreloader from "@/components/sections/Preloader";

export default {
  data() {
    return {
      currencies: [
        {
          id: 1,
          acronym: "USD",
        },
      ],
      withdrawal_ways: [],
      payment_configs: [],
      withdrawalData: {
        withdrawal_way_id: "",
        payment_config_id: "",
        currency_id: 1,
        amount: "",
      },
      withdrawal_way: null,
      payment_config: {
        id: "",
        withdrawal_way_id: "",
        metadata: null,
      },
      walletBalance: "",
      isEditing: false,
      checkoutData: null,
      errors: [],
    };
  },
  components: { SectionPreloader },
  mixins: [global],
  beforeMount() {},
  mounted() {
    $("#sectionPreloader").fadeIn();

    if (this.curUser.person != undefined) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/person/get/" +
            this.curUser.person.id
        )
        .then((response) => {
          this.walletBalance = response.data.person.wallet.amount;
          this.getWithdrawalWays();
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            this.walletBalance = "";

            console.log(error.response.data.error);
          }
        });
    } else if (this.curUser.company != undefined) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/company/get/" +
            this.curUser.company.id
        )
        .then((response) => {
          this.walletBalance = response.data.company.wallet.amount;
          this.getWithdrawalWays();
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          this.walletBalance = "";

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            console.log(error.response.data.error);
          }
        });
    }
  },
  methods: {
    getWithdrawalWays: function() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/withdrawal-way/all-active")
        .then((response) => {
          //console.log(response.data.withdrawal_ways);
          this.withdrawal_ways = response.data.withdrawal_ways;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            $("#sectionPreloader").fadeOut();

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }
        })
        .finally(() => {
          axios
            .get(process.env.VUE_APP_API_URL + "/api/v1/payment-config/list")
            .then((response) => {
              this.payment_configs = response.data.payment_configs;

              this.payment_configs.forEach((pc, key) => {
                let updated_payment = false;

                let withdrawal_way_idx = this.withdrawal_ways.findIndex(
                  (x) => x.id == pc.withdrawal_way_id
                );

                if (withdrawal_way_idx >= 0) {
                  if (
                    this.payment_configs[key].metadata.length ==
                    this.withdrawal_ways[withdrawal_way_idx].metadata.length
                  ) {
                    this.withdrawal_ways[withdrawal_way_idx].metadata.forEach(
                      (m) => {
                        if (
                          this.payment_configs[key].metadata.findIndex(
                            (x) => x.id == m.id
                          ) < 0
                        ) {
                          updated_payment = true;
                        }
                      }
                    );
                  } else {
                    updated_payment = true;
                  }
                } else {
                  updated_payment = true;
                }

                if (updated_payment) {
                  axios
                    .delete(
                      process.env.VUE_APP_API_URL +
                        "/api/v1/payment-config/delete/" +
                        pc.id
                    )
                    .then(() => {
                      this.payment_configs.splice(key, 1);
                    })
                    .catch((error) => {
                      this.errors = [];

                      if (error.response != undefined) {
                        this.errors = error.response.data.errors ?? [];

                        console.log(error.response.data.error);
                      }
                    });
                }
              });

              $("#sectionPreloader").fadeOut();

              //console.log(response.data.message);
            })
            .catch((error) => {
              this.errors = [];

              if (error.response != undefined) {
                this.errors = error.response.data.errors ?? [];

                EventBus.$emit("showErrors", {
                  msg: error.response.data.error,
                });

                console.log(error.response.data.error);
              }
            });
        });
    },
    updateConfigPayment: function() {
      this.errors = [];

      axios
        .put(
          process.env.VUE_APP_API_URL + "/api/v1/payment-config/update",
          this.payment_config
        )
        .then((response) => {
          let payment_config_index = this.payment_configs.findIndex(
            (x) => x.id == this.payment_config.id
          );

          this.payment_configs[payment_config_index] = this._clone(
            response.data.payment_config
          );
          this.payment_config.id = this.withdrawalData.payment_config_id =
            response.data.payment_config.id;

          this.isEditing = false;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }
        });
    },
    addConfigPayment: function() {
      this.errors = [];

      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/payment-config/create",
          this.payment_config
        )
        .then((response) => {
          this.payment_configs.push(_.clone(response.data.payment_config));
          this.payment_config = _.clone(response.data.payment_config);
          this.withdrawalData.payment_config_id =
            response.data.payment_config.id;

          this.isEditing = false;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }
        });
    },
    withdraw: function() {
      this.errors = [];

      if ($("button[type=submit]").hasClass("disabled")) {
        return false;
      }

      $("button[type=submit]").addClass("disabled");

      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/store",
          this.withdrawalData
        )
        .then((response) => {
          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.withdrawalData = {
            withdrawal_way_id: "",
            payment_config_id: "",
            currency_id: 1,
            amount: "",
          };

          this.withdrawal_way = null;

          this.redirectWithDelay("/retiro-saldo");

          $("button[type=submit]").removeClass("disabled");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            $("button[type=submit]").removeClass("disabled");

            console.log(error.response.data.error);
          }
        });
    },
    changePaymentMethod: function(withdrawal_way_id) {
      this.errors = [];

      if (this.withdrawalData.withdrawal_way_id != withdrawal_way_id) {
        this.isEditing = false;

        $("input[type=radio][name=paymentMethod]").prop("checked", false);
        this.withdrawalData.withdrawal_way_id = withdrawal_way_id;

        let withdraw_index = this.withdrawal_ways.findIndex(
          (x) => x.id == withdrawal_way_id
        );

        let config_index = this.payment_configs.findIndex(
          (x) => x.withdrawal_way_id == withdrawal_way_id
        );

        this.withdrawal_way = this.withdrawal_ways[withdraw_index];

        if (config_index >= 0) {
          this.withdrawalData.payment_config_id = this.payment_configs[
            config_index
          ]["id"];

          this.payment_config = {
            id: this.payment_configs[config_index]["id"],
            withdrawal_way_id: withdrawal_way_id,
            metadata: this.payment_configs[config_index].metadata.map(function(
              m
            ) {
              return {
                metadata_id: m.id,
                label: m.label,
                name: m.name,
                value: m.value,
              };
            }),
          };
        } else {
          this.payment_config = {
            id: "",
            withdrawal_way_id: withdrawal_way_id,
            metadata: this.withdrawal_way.metadata.map(function(m) {
              return {
                metadata_id: m.id,
                label: m.label,
                name: m.name,
                value: "",
              };
            }),
          };

          if (this.payment_config.metadata.length > 0) {
            this.isEditing = true;
          } /* else {
            this.addConfigPayment();
          }*/
        }

        this.processPayment();
      }
    },
    cancelEditPayment: function(withdrawal_way_id) {
      this.isEditing = false;
      this.errors = [];

      let withdraw_index = this.withdrawal_ways.findIndex(
        (x) => x.id == withdrawal_way_id
      );

      let config_index = this.payment_configs.findIndex(
        (x) => x.withdrawal_way_id == withdrawal_way_id
      );

      this.withdrawal_way = this.withdrawal_ways[withdraw_index];

      if (config_index >= 0) {
        this.payment_config.metadata = this.payment_configs[
          config_index
        ].metadata.map(function(m) {
          return {
            metadata_id: m.id,
            label: m.label,
            name: m.name,
            value: m.value,
          };
        });
      } else {
        this.payment_config.metadata = this.withdrawal_way.metadata.map(
          function(m) {
            return {
              metadata_id: m.id,
              label: m.label,
              name: m.name,
              value: "",
            };
          }
        );
      }
    },
    processPayment: function() {
      this.errors = [];

      if (this.withdrawalData.withdrawal_way_id && this.withdrawalData.amount) {
        const withdrawal_commission = {
          withdrawal_way_id: this.withdrawalData.withdrawal_way_id,
          amount: this.withdrawalData.amount,
        };

        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/v1/requests/withdrawal/commissions",
            withdrawal_commission
          )
          .then((response) => {
            this.checkoutData = response.data.commissions;
            //console.log(response.data.message);
          })
          .catch((error) => {
            this.errors = [];

            this.checkoutData = null;

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              EventBus.$emit("showErrors", {
                msg: error.response.data.error,
              });

              console.log(error.response.data.error);
            }
          });
      }
    },
  },
};
</script>
