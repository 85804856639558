<template>
  <div>
    <div class="card">
      <div class="card-header d-sm-flex d-block border-0 pb-0">
        <div class="pr-3 mb-sm-0 mb-3 mr-auto">
          <h4 class="fs-20 text-black mb-1">Últimas transferencias</h4>
        </div>
      </div>
      <div class="card-body">
        <div v-if="userWithLatestTransfers.length" class="row">
          <template v-for="user in userWithLatestTransfers">
            <div
                class="col-12 col-lg-4 col-md-6 col-sm-12 text-left c-pointer"
                :key="user.transfer_id"
                @click="navigateToCreateTransfer(user.email)"
            >
              <div class="row">
                <div class="image-bx mb-3 text-center col-2">
                  <v-avatar>
                    <img
                        class="img-fluid"
                        :src="user.avatar"
                        alt="Sin avatar"
                    >
                  </v-avatar>
                </div>
                <div class="col-9">
                  <h6 class="fs-16 mb-0">
                    <div class="text-black">{{ user.username || 'sin nombre de usuario' }}</div>
                  </h6>
                  <div class="fs-12">{{ user.email }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="row">
          <div class="h6 col-12 col-lg-12 col-md-6 col-sm-12 text-left">
            No hay resultados para mostrar. Haga su primera
            <router-link
                :to="{ name: 'transferencia-new' }"
                title="Ir al formulario crear transferencia"
            >
              <span> transferencia.</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from "@/main";
import image from "@/helpers/image";

export default {
  name: "LatestTransfers",
  mixins: [image],

  props: {
    limitTransfers: {
      type: Number,
      default: 5
    },
  },

  data() {
    return {
      userWithLatestTransfers: [],
      total: 0,
    };
  },

  methods: {
    async getLatestTransfers() {
      try {
        const apiUrl = process.env.VUE_APP_API_URL + `/api/v1/user/have-recent-transfers`;
        const { data: { users } } = await axios.post(apiUrl, { recent: this.limitTransfers });

        this.userWithLatestTransfers = users;

        this.total = this.userWithLatestTransfers.length;
      } catch ({ response: { data: { message } } }) {
        EventBus.$emit("showErrors", {
          msg: message
        });
      }
    },

    navigateToCreateTransfer(email) {
      this.$router.push({ name: 'transferencia-new', params: { user_email: email } });
    },

    async setAvatar() {
      this.userWithLatestTransfers.map(async (user) => {
        await this.getAvatar(user.client_id, user.type);
        user.avatar = this.avatar;
      });
    }
  },

  async mounted() {
    await this.getLatestTransfers();

    await this.setAvatar();
  }

};
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>
