<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="show"
          width="900"
          persistent
      >
        <v-card>
          <v-card-text>
            <slot v-bind:details="details" name="details"></slot>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="onClose"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "DetailsDialog",

  data() {
    return {
      show: false,
    };
  },

  props: {
    details: {
      type: Object,
      require:true,
    }
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    },

    onClose() {
      this.close();

      this.$emit('closed')
    }
  }
};
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>
