<template>
  <div class="container">
    <div class="row">
      <div class="col-12"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6 font-weight-bold">Detalles de la recarga</div>
          <div class="col-12 col-lg-6 col-md-6 text-left text-md-right text-sm-right text-lg-right">
            <div><span class="font-weight-bold">ID: </span>#{{ recharge.id }}</div>
            <div><span class="font-weight-bold">Referencia: </span>{{ recharge.reference }}</div>
            <div><span class="font-weight-bold">Fecha: </span>{{ recharge.date }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4 col-md-4 col-sm-4">
            <span class="font-weight-bold">Método de pago: </span>
            <span>{{ recharge.recharge_way.name }}</span>
          </div>
          <div class="col-12 col-lg-6 col-md-6 col-sm-6">
            <span class="font-weight-bold h4">Monto: </span>
            <span class="font-weight-bold h4">{{ recharge.amount_display }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="font-weight-bold">Estado: </span>
            <span class="">{{ recharge.status.display_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RechargeDetails",

  props: {
    recharge: {
      type: Object,
      required: true
    }
  },
};
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>


