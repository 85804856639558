var carouselReview = function () {
	/*  testimonial one function by = owl.carousel.js */
	/*jQuery('.testimonial-one').owlCarousel({
		loop: true,
		margin: 10,
		nav: false,
		center: true,
		dots: false,
		navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
		responsiveClass: true,
		responsive: {
			0: {
				items: 3
			},
			500: {
				items: 4
			},
			700: {
				items: 5
			},
			991: {
				items: 6
			},
			1200: {
				items: 4
			},
			1600: {
				items: 5
			}
		}
	})*/
}

var handleMenuClick = function () {
	jQuery('ul#menu.metismenu > li').on('click', function (e) {
		e.stopPropagation();
		jQuery('ul#menu.metismenu > .mm-active').removeClass('mm-active');
		jQuery(this).addClass('mm-active');
		$('#main-wrapper').removeClass("menu-toggle");
		$("#main-wrapper .hamburger").removeClass("is-active");
	});
}

jQuery(document).ready(function () {
	carouselReview();
	handleMenuClick();
});

export {
	carouselReview, handleMenuClick
}