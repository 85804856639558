<template>
  <div class="LayoutPage">
    <div id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="main-wrapper">
      <section-header-frontend />
      <slot />
      <section-footer-frontend />
    </div>
  </div>
</template>

<style src="@/assets/scss/frontend/style.scss" lang='scss'></style>

<script>
import SectionHeaderFrontend from "@/components/sections/HeaderFrontend";
import SectionFooterFrontend from "@/components/sections/FooterFrontend";

export default {
  components: {
    SectionHeaderFrontend,
    SectionFooterFrontend
  },
  mounted() {
    const el = document.body;
    el.classList.remove("wallet-loaded");

    $('#preloader').fadeOut(500);
  }
};
</script>