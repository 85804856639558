<template>
  <!-- Header -->
  <header class="header">
    <nav class="navbar navbar-expand-lg header-nav fixed-top">
      <div class="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
          <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <router-link
          to="/"
          class="navbar-brand logo"
        >
          <img src="@/assets/img/logo-full.png" class="img-fluid" alt="Logo" />
        </router-link>
      </div>
      <div class="main-menu-wrapper">
        <div class="menu-header">
          <router-link to="/" class="menu-logo" v-if="currentPath != 'home'">
            <img
              src="@/assets/img/logo-full.png"
              class="img-fluid"
              alt="Logo"
            />
          </router-link>
          <div v-else class="menu-logo">
            <img
              src="@/assets/img/logo-full.png"
              class="img-fluid"
              alt="Logo"
            />
          </div>
          <a id="menu_close" class="menu-close" to="javascript:void(0);"
            ><i class="fas fa-times"></i
          ></a>
        </div>
        <ul class="main-nav">
          <li class>
            <router-link to="/#nosotros" @click.native="scrollFix('#nosotros')"
              >{{ $t('header.about_us') }}</router-link
            >
          </li>
          <li class>
            <router-link
              to="/#servicios"
              @click.native="scrollFix('#servicios')"
              >{{ $t('header.services') }}</router-link
            >
          </li>
          <li class>
            <router-link
              to="/#faqs"
              @click.native="scrollFix('#faq')"
              >{{ $t('header.faq') }}</router-link
            >
          </li>
          <li class>
            <router-link
              to="/#contactenos"
              @click.native="scrollFix('#contactenos')"
              >{{ $t('header.contact_us') }}</router-link
            >
          </li>
          <li class="has-submenu">
            <a href="javascript:void(0);" class="text-decoration-none">
              {{ $t('header.clients_area') }}
              <i class="fas fa-chevron-down"></i>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/entrar">{{ $t('header.login') }}</router-link>
              </li>
              <li>
                <router-link to="/registrarse">{{ $t('header.register') }}</router-link>
              </li>
            </ul>
          </li>
<!--          <switch-lang></switch-lang>-->

          <li class="has-submenu">
            <a href="javascript:void(0);" class="text-decoration-none">
              <i class="fas fa-globe"></i>
              {{ $i18n.locale }}
            </a>
            <ul class="submenu">
              <li class
                  v-for="locale in $i18n.availableLocales"
                  v-bind:key="locale">
                <a href="javascript:void(0);" @click="changeLang(locale)">
                  {{ locale }}</a>
              </li>
            </ul>
          </li>

        </ul>
      </div>
    </nav>
  </header>
  <!-- /Header -->
</template>

<style src="@/assets/scss/frontend/header.scss" lang="scss" scoped></style>

<script>
import global from "@/helpers/global";
import i18n from "../../i18n";
// import switchLang from "../SwitchLang";

export default {
  mixins: [global],
  components: {
    // switchLang
  },
  mounted() {
    var $wrapper = $(".main-wrapper");

    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function() {
      $wrapper.toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
      return false;
    });

    $(document).on("click", "#menu_close", function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });
    $(".main-nav ul li:not(.submenu) a").click(function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
    });
    $(".sidebar-overlay, .main-menu-wrapper .main-nav a").click(function() {
      if ($(".sidebar-overlay").hasClass("opened")) {
        $(".main-menu-wrapper .menu-header .menu-close").trigger("click");
      }
    });

    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function(hashbang) {
      location.hash = hashbang;
      if (this.$route.hash) {
        location.href = this.$route.hash;
        
        const innerWidth = $(window).innerWidth();
        if (innerWidth < 991) {
          $("#menu_close").trigger("click");
          setTimeout(function() {
            const scrollTop = window.pageYOffset || $(hashbang).offset().top;
            $("html, body").animate(
              {
                scrollTop: ( scrollTop - 100 ),
              },
              300
            );
          }, 10);
        } else {
          const scrollTop = window.pageYOffset || $(hashbang).offset().top;
          window.scrollTo(0, ( scrollTop - 100 ));
        }
      }
    },
    changeLang: function (lang) {
      i18n.locale = lang;
    }
  },
};
</script>
