<template>
  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <!-- Tab Content -->
          <div class="account-content">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-12 col-lg-6 mx-auto">
                <form @submit.prevent="confirmEmail">
                  <div class="row">
                    <div class="col-md-10 mt-5 mb-0">
                      <h2>Confirmar Email</h2>
                      <p>Por favor, rellene el siguiente formulario para confirmar su email.</p>
                    </div>
                  </div>
                  <!-- Information -->
                  <div class="card">
                    <div class="card-body">
                      <div class="row form-row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>
                              Email
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-lower-case
                                v-model="confirmData.email"
                                type="email"
                                class="form-control"
                            />
                            <small v-if="errors.email" class="text-danger">{{ errors.email[0] }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Código de verificación
                              <span class="text-danger">*</span>
                            </label>
                            <input v-model="confirmData.email_verification_code" type="text" class="form-control" />
                            <small
                              v-if="errors.email_verification_code"
                              class="text-danger"
                            >{{ errors.email_verification_code[0] }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Information -->
                  <div class="submit-section submit-btn-bottom mt-3 mb-5">
                    <button type="submit" class="btn btn-primary">Confirmar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Tab Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      confirmData: {
        email: "",
        email_verification_code: ""
      },
      errors: []
    };
  },
  mixins: [global],
  beforeMount() {
    this.confirmData.email = this.$route.params.email;
    this.confirmData.email_verification_code = this.$route.params.code;
  },
  mounted() {
    this.checkIfLoggedIn();
  },
  methods: {
    confirmEmail() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/v1/security/confirm-email",
          this.confirmData
        )
        .then(() => {
          this.$router.push("/email-confirmado");
          //console.log(response.data.message);
        })
        .catch(error => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error
            });

            console.log(error.response.data.error);
          }
        });
    }
  }
};
</script>
