<template>
  <div class="container-fluid">
    <div class="page-titles d-flex justify-content-between align-items-center">
      <h2 class="text-black font-w600 w-50">Retiros de Saldo</h2>
      <router-link
        class="btn btn-rounded btn-primary"
        to="/retiro-saldo/nuevo"
      >
        <span class="btn-icon-left text-primary"
          ><i class="fa fa-plus" aria-hidden="true"></i> </span
        >Nuevo
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="table-withdrawals"
                class="table display responsive w-100"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      withdrawal_requests: [],
      dataTable: null,
      tableFields: ["id", "date", "amount_display", "withdrawal_way.name"],
      errors: [],
    };
  },
  mixins: [global, table],
  mounted() {
    var options = {
      order: [[1, "desc"]],
      columnDefs: [
        {
          targets: 0,
          className: "dt-center",
        },
        {
          targets: 2,
          responsivePriority: 1,
          className: "dt-body-right",
        },
        {
          targets: -1,
          responsivePriority: 2,
          className: "dt-body-right",
        },
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-withdrawals", options);

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/list")
      .then((response) => {
        this.withdrawal_requests = response.data.withdrawalRequests;
        //console.log(response.data.withdrawalRequests);
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      })
      .finally(() => {
        this.generateTableWithData(this.withdrawal_requests);

        this.renderTable();
      });
  },
  methods: {
    tableActions: function(id) {
      let withdrawal_request = this._clone(
        this.withdrawal_requests.filter(function(p) {
          return p.id == id;
        })[0]
      );

      let actions = '<div class="table-action">';

      actions +=
        `<span class="my-lg-0 my-1 badge light badge-` +
        withdrawal_request.status.name.toLowerCase() +
        ` mx-2 no-link" href="javascript:void(0)">` +
        withdrawal_request.status.display_name +
        `</span>`;
        
      //actions += '</div>';

      return actions;
    },
  },
};
</script>
