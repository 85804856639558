import { render, staticRenderFns } from "./FooterFrontend.vue?vue&type=template&id=7da55a70&scoped=true&"
var script = {}
import style0 from "@/assets/scss/frontend/footer.scss?vue&type=style&index=0&id=7da55a70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da55a70",
  null
  
)

export default component.exports