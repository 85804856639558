import { EventBus } from "@/main";

export default {
    data() {
        return {
            avatar: null,
            allowedMimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            pdfMimeTypes: ['application/pdf'],
            bannerActive: { image: null }
        };
    },
    methods: {
        async getAvatar(id, profileType = 'person') {
            const url = `${process.env.VUE_APP_API_URL}/api/v1/clients/${profileType}/avatar/${id}`;
            await axios
                .get(url, { responseType: 'arraybuffer' })
                .then((response) => {
                    const raw = Buffer.from(response.data).toString('base64');
                    this.avatar = `data:${response.headers["content-type"]};base64,${raw}`;
                })
                .catch((error) => {
                    this.errors = [];
                    this.errors = error.response.data.errors ?? [];

                    EventBus.$emit("showErrors", {
                        msg: error.response.data.error,
                    });

                    this.avatar = null;
                });
        },
        async getActiveBanner() {
            const bannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/active`;

            try {
                const { data: { banner } } = await axios.get(bannerUrl);

                this.bannerActive = banner;
            } catch ({ response: { data: { error } } }) {
                EventBus.$emit('showErrors', {
                    msg: error,
                });
            }
        },
        maxSizeRule() {
            return (image) => !image || image.size < process.env.VUE_APP_IMAGE_FILE_MAX_SIZE || this.maxSizeImageRuleText;
        },
        mimeTypeRule(includePdf = false) {
            return (image) => !image || this.checkMimeTypeRule(image.type, includePdf) || this.mimeTypeRuleText(includePdf);
        },
        checkMimeTypeRule(type, includePdf = false) {
            if (includePdf) {
                return this.allAllowedMimeTypes.includes(type)
            }

            return this.allowedMimeTypes.includes(type);
        },
        mimeTypeRuleText(includePdf = false) {
            const mimeTypes = includePdf ? this.allAllowedMimeTypes : this.allowedMimeTypes;

            const extensions = mimeTypes.map((mime) => {
                const posDash = mime.indexOf('/') + 1;

                return mime.substr(posDash, mime.length - posDash);
            });

            return `Tipo de archivo permitidos: ${extensions.join(', ')}!`;
        },
    },
    computed: {
        maxSizeImageRuleText() {
            const sizeText = this.$options.filters.prettyBytes(process.env.VUE_APP_IMAGE_FILE_MAX_SIZE, 0);

            return `Tamaño máximo permitido ${sizeText}!`;
        },
        allAllowedMimeTypes() {
            return this.allowedMimeTypes.concat(this.pdfMimeTypes);
        },
        allowedMimeTypesAsString() {
            return this.allowedMimeTypes.join(', ');
        },
    }
};
