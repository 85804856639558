<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
    </component>
    <messages></messages>
    <floating-whatsapp-button></floating-whatsapp-button>
  </v-app>
</template>

<!--style src="@/assets/scss/style.scss" lang='scss'></!--style-->

<script>
import FloatingWhatsappButton from './components/FloatingWhatsappButton';
const default_layout = "Page";
export default {
  components: { FloatingWhatsappButton },
  computed: {
    layout() {
      return 'Layout' + (this.$route.meta.layout || default_layout);
    }
  },
  created() {
    // nothing defined here (when this.$route.path is other than "/")
    //console.log(this.$route, this.$route.meta.layout);
  },
  updated() {
    // something defined here whatever the this.$route.path
    //console.log(this.$route, this.$route.meta.layout);
  }
};
</script>
