<template>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t(lang_key+'_question') }}
        <template v-slot:actions>
          <v-icon color="primary">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        {{ $t(lang_key+'_answer') }}
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  name: "FaqsItem",

  props: {
    question: {
      type: Object,
      require: true
    },
    lang_key: {
      type: String,
      require: true
    }
  }
};
</script>
