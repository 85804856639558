<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">Transacciones</h2>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="table-transactions"
                class="table display responsive w-100"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <details-dialog :details="transactionSelected" ref="transactionDetailsDialog">
      <template v-slot:details="transactionSelected">
        <transaction-details :transaction="transactionSelected.details"></transaction-details>
      </template>
    </details-dialog>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import DetailsDialog from '../Shared/DetailsDialog';
import TransactionDetails from './Details';

export default {
  components: { TransactionDetails, DetailsDialog },

  data() {
    return {
      transactions: [],
      dataTable: null,
      tableFields: [
        "id",
        "transaction_type.display_name",
        "date",
        "amount_display",
        "payment_type",
      ],
      transactionSelected: null,
      errors: [],
    };
  },
  mixins: [global, table],
  mounted() {
    var options = {
      order: [[2, "desc"]],
      columnDefs: [
        {
          targets: 0,
          className: "dt-center",
        },
        {
          targets: 1,
          responsivePriority: 1,
        },
        {
          targets: 3,
          responsivePriority: 2,
          className: "dt-body-right",
        },
        {
          targets: -1,
          className: "dt-body-right",
        },
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-transactions", options);

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/transactions/list")
      .then((response) => {
        this.transactions = response.data.transactions;
        //console.log(response.data.transactions);
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      })
      .finally(async () => {
        if (this.transactions.length > 0) {
          await this.setPaymentTypes();
        } else {
          this.generateTableWithData(this.transactions);
          this.renderTable();
        }

        let component = this;

        $("#table-transactions").on("click", ".action-details", async function () {
          $("#table-transactions tr.selected").removeClass("selected");
          $(this)
              .closest("tr")
              .addClass("selected");

          component.transactionSelected = component.transactions.find((transaction) => transaction.id == $(this).attr("attr_id"));

          component.$refs.transactionDetailsDialog.open();
        });
      });
  },
  methods: {
    setPaymentTypes: function() {
      var tc = this._clone(this.transactions);
      this.transactions = [];
      tc.forEach(async (t, key) => {
        switch (t.transaction_type.name) {
          case "recharge":
            tc[key].payment_type = await this.getRechargePayment(
              t.recharge_request.recharge_way_id
            );
            this.transactions = tc;
            this.generateTableWithData(this.transactions);
            this.renderTable();
            break;
          case "withdrawal":
            tc[key].payment_type = await this.getWithdrawalPayment(
              t.withdrawal_request.withdrawal_way_id
            );
            this.transactions = tc;
            this.generateTableWithData(this.transactions);
            this.renderTable();
            break;
          default:
            tc[key].payment_type = "-";
            this.transactions = tc;
            this.generateTableWithData(this.transactions);
            this.renderTable();
        }
      });
    },
    async getRechargePayment(id) {
      let recharge_payment = "";
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/recharge-way/get/" + id)
        .then((response) => {
          recharge_payment = response.data.recharge_way.name;
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];
            //console.log(error.response.data.error);
          }

          recharge_payment = "";
        });

      return recharge_payment;
    },
    async getWithdrawalPayment(id) {
      let withdrawal_payment = "";
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/withdrawal-way/get/" + id)
        .then((response) => {
          withdrawal_payment = response.data.withdrawal_way.name;
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];
            //console.log(error.response.data.error);
          }

          withdrawal_payment = "";
        });

      return withdrawal_payment;
    },
    tableActionsByObject(transaction) {
      let actions = `<div class="table-action text-right">`;

      actions +=
          `<span class="my-lg-0 my-1 badge light badge-` +
          transaction.status.name.toLowerCase() +
          ` mx-2 no-link" href="javascript:void(0)">` +
          transaction.status.display_name +
          `</span>`;

      actions += this.generateDetailsAction(transaction.id)

      actions += '</div>';

      return actions;
    },
  },
};
</script>
