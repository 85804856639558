<template>
  <div class="container-fluid">
    <div class="page-titles d-flex justify-content-between align-items-center">
      <h2 class="text-black font-w600 w-50">Recargas de Saldo</h2>
      <router-link
        class="btn btn-rounded btn-primary"
        to="/recarga-saldo/nueva"
      >
        <span class="btn-icon-left text-primary"
          ><i class="fa fa-plus" aria-hidden="true"></i> </span
        >Nueva
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="table-recharges"
                class="table display responsive w-100"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Método de Pago</th>
                    <th class="no-sort"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <details-dialog :details="rechargeSelected" ref="rechargeDetailsDialog">
      <template v-slot:details="rechargeSelected">
        <recharge-details :recharge="rechargeSelected.details"></recharge-details>
      </template>
    </details-dialog>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import DetailsDialog from '../Shared/DetailsDialog';
import RechargeDetails from './Details';

export default {
  components: { RechargeDetails, DetailsDialog },
  data() {
    return {
      recharge_requests: [],
      dataTable: null,
      tableFields: ["id", "date", "amount_display", "recharge_way.name"],
      rechargeSelected: null,
      errors: [],
    };
  },
  mixins: [global, table],
  mounted() {
    var options = {
      order: [[1, "desc"]],
      columnDefs: [
        {
          targets: 0,
          className: "dt-center",
        },
        {
          targets: 2,
          responsivePriority: 1,
          className: "dt-body-right",
        },
        {
          targets: -1,
          responsivePriority: 2,
          className: "dt-body-right",
        },
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-recharges", options);

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/requests/recharge/list")
      .then((response) => {
        this.recharge_requests = response.data.recharge_requests;
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      })
      .finally(() => {
        this.generateTableWithData(this.recharge_requests);

        const component = this;

        $("#table-recharges").on("click", ".action-details", async function () {
          $("#table-recharges tr.selected").removeClass("selected");
          $(this)
              .closest("tr")
              .addClass("selected");

          component.rechargeSelected = component.recharge_requests.find((recharge) => recharge.id == $(this).attr("attr_id"));

          component.$refs.rechargeDetailsDialog.open();
        });

        this.renderTable();
      });
  },
  methods: {
    tableActionsByObject: function(recharge_request) {

      let actions = '<div class="table-action">';

      actions +=
        `<span class="my-lg-0 my-1 badge light badge-` +
        recharge_request.status.name.toLowerCase() +
        ` mx-2 no-link" href="javascript:void(0)">` +
        recharge_request.status.display_name +
        `</span>`;

      actions += this.generateDetailsAction(recharge_request.id);

      return actions;
    },
  },
};
</script>
