<template>
  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <!-- Login Tab Content -->
          <div class="account-content">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-7 login-left">
                <img src="@/assets/img/login-graphic01.png" class="img-fluid" alt="Doccure Login" />
              </div>
              <div class="col-md-12 col-lg-5 login-right">
                <div class="login-header mb-2">
                  <h3>¿Olvidaste la contraseña?</h3>
                  <p
                    class="small text-muted mt-3"
                  >Introduzca su usuario o email para obtener un enlace para restablecer la contraseña</p>
                </div>
                <form @submit.prevent="reset">
                  <div class="form-group form-focus" :class="{ 'focused': focused }">
                    <input
                      v-lower-case
                      v-model="forgot"
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      required
                      @focus="focused = true"
                      @blur="focused = false"
                    />
                    <label class="focus-label">Usuario/Correo electrónico</label>
                  </div>
                  <button
                    class="btn btn-primary btn-block btn-lg login-btn"
                    type="submit"
                  >Recuperar Contraseña</button>
                  <div class="login-or">
                    <span class="or-line"></span>
                    <span class="span-or">o</span>
                  </div>
                  <div class="text-center dont-have">
                    ¿No tienes una cuenta?
                    <router-link to="/registrarse">Registrate</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Login Tab Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<style src="@/assets/scss/frontend/content.scss" lang='scss' scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      focused: false,
      forgot: ""
    };
  },
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");
  },
  methods: {
    reset() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/user/reset-password/" + this.forgot)
        .then(() => {
          this.$router.push("/solicitud-enviada")
          //console.log(response.data.message);
        })
        .catch(error => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error
            });

            console.log(error.response.data.error);
          }
        });
    }
  }
};
</script>
