export default {
    data() {
        return {
            routesByNotificationType: {
                recharge: 'recarga-saldo',
                transfer: 'transferencias',
                withdrawal: 'retiro-saldo',
            },
            selectedNotification: null,
        };
    },

    methods: {
        async read(id = null) {
            id = id || this.$vnode.key;

            if (!this.isRead()) {
                await axios.put(process.env.VUE_APP_API_URL + `/api/v1/notifications/mark-as-read/${id}`);
            }
        },

        isRouteNotificationList() {
            return this.$route.name.includes('notifications');
        },

        getRouteByNotificationType(type) {
            return this.routesByNotificationType[type];
        },

        hasRouteToViewNotification(type) {
            return this.getRouteByNotificationType(type) !== undefined;
        },

        getNotificationById(id = null, notifications = this.data) {
            return notifications.find(notification => notification.id === id || this.$vnode.key);
        },

        navigateToViewNotification(routeName) {
            this.$router.push({ name: routeName });

        },

        isRead() {
            return this.selectedNotification && !!this.selectedNotification.read_at
        }
    }
};
