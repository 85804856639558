<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">Recargar Saldo</h2>
    </div>
    <div class="row">
      <div class="col-xl-7">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <section-preloader />
                <form @submit.prevent="recharge" method="POST">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="currency"
                        >Moneda <span class="text-danger">*</span></label
                      >
                      <v-autocomplete
                        class="form-control"
                        :items="currencies"
                        flat
                        hide-details
                        hide-no-data
                        solo-inverted
                        item-text="acronym"
                        item-value="id"
                        v-model="rechargeData.currency_id"
                        @change="processPayment()"
                      ></v-autocomplete>
                      <small v-if="errors.currency_id" class="text-danger">{{
                        errors.currency_id[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <label for="amount"
                        >Monto <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          step="0.01"
                          min="0.01"
                          v-model="rechargeData.amount"
                          @change="processPayment()"
                        />
                      </div>
                      <small v-if="errors.amount" class="text-danger">{{
                        errors.amount[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-0"
                        >Método de pago
                        <span class="text-danger">*</span></label
                      >
                      <div class="d-block my-3">
                        <div
                          v-for="(value, key) in recharge_ways"
                          v-bind:key="key"
                          class="custom-control custom-radio mb-2"
                        >
                          <input
                            name="paymentMethod"
                            :checked="
                              rechargeData.recharge_way_id == value.id
                                ? 'checked'
                                : false
                            "
                            type="radio"
                            class="custom-control-input"
                          />
                          <label
                            @click="changePaymentMethod(value.id)"
                            class="custom-control-label"
                            for="paymentMethod"
                            >{{ value.name }}</label
                          >
                        </div>
                        <small
                          v-if="errors.recharge_way_id"
                          class="text-danger"
                          >{{ errors.recharge_way_id[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-row form-group-box mt-2"
                    v-if="rechargeData.metadata.length"
                  >
                    <div
                      v-for="(field, key) in rechargeData.metadata"
                      v-bind:key="key"
                      class="col-md-12 mb-3"
                    >
                      <label
                        >{{ field.label }}
                        <span class="text-danger">*</span></label
                      >
                      <input
                        v-model="rechargeData.metadata[key].value"
                        type="text"
                        class="form-control"
                        placeholder=""
                      />
                      <small v-if="errors[field.name]" class="text-danger">{{
                        errors[field.name][0][0]
                      }}</small>
                    </div>
                  </div>
                  <div v-if="checkoutData">
                    <hr class="mb-4" />
                    <h4 class="fs-18 mb-3">Monto a acreditar</h4>
                    <ul class="list-group mb-3 pl-0">
                      <li
                        v-if="checkoutData.recharge_way > 0"
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h6 class="my-0">Comisión método de pago</h6>
                          <small class="text-muted"
                            >Cobrada por la pasarela de pago</small
                          >
                        </div>
                        <span class="text-muted"
                          >-{{
                            formatCurrency(checkoutData.recharge_way)
                          }}</span
                        >
                      </li>
                      <li
                        v-if="checkoutData.system > 0"
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h6 class="my-0">Comisión PagosUnDolar</h6>
                          <small class="text-muted"
                            >Cobrada por el sistema</small
                          >
                        </div>
                        <span class="text-muted"
                          >-{{ formatCurrency(checkoutData.system) }}</span
                        >
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Total (USD)</span>
                        <strong>{{
                          formatCurrency(checkoutData.final_amount)
                        }}</strong>
                      </li>
                    </ul>
                  </div>
                  <hr class="mb-4" />
                  <div class="custom-control custom-checkbox mb-2">
                    <input
                      v-model="rechargeData.accept_terms"
                      type="checkbox"
                      class="custom-control-input"
                      id="same-address"
                    />
                    <label class="custom-control-label" for="same-address"
                      >Términos y Condiciones. Haga clic
                      <a
                        href="javascript:void();"
                        data-toggle="modal"
                        data-target="#termsModal"
                        >aquí</a
                      >
                      para ver.</label
                    >
                  </div>
                  <small
                    v-if="errors.accept_terms"
                    class="d-block text-danger"
                    >{{ errors.accept_terms[0] }}</small
                  >
                  <button
                    class="btn btn-primary btn-lg btn-block mt-8"
                    type="submit"
                  >
                    Enviar solicitud
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <div>
                  <h4 class="fs-20 text-black font-w600">
                    Datos para recargar
                  </h4>
                </div>
              </div>
              <div class="card-body pt-5">
                <div class="pr-3 mb-3">
                  <span class="text-black fs-18 font-w500">Western Union</span>
                </div>
                <div class="p-3 bgl-dark rounded fs-14 d-flex">
                  <p class="mb-0">
                    Chase Bank a Nombre de<br />
                    <span class="ml-4"
                      >MY COMPANY NAME LLC<br
                    /></span>
                    <span class="ml-4">Cuenta: 000000000<br /></span>
                    <span class="ml-4">Ruta: 123456789<br /></span>
                    <span class="ml-4"
                      >Dirección: 000 First ave apt 000 , FL
                      00000<br
                    /></span>
                    <span class="ml-4"
                      >Número de Teléfono: 1(000)0000000<br
                    /></span>
                    <span class="fs-13 d-block mt-3 font-weight-bold"
                      >* Muy importante: aclarar en western que es un depósito
                      directo en la cuenta bancaria.</span
                    >
                  </p>
                </div>
                <div class="pr-3 mt-6 mb-3">
                  <span class="text-black fs-18 font-w500">Zelle</span>
                </div>
                <div class="p-3 bgl-dark rounded fs-14 d-flex">
                  <p class="mb-0">
                    zelle@dominio.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

import SectionPreloader from "@/components/sections/Preloader";

export default {
  data() {
    return {
      currencies: [
        {
          id: 1,
          acronym: "USD",
        },
      ],
      recharge_ways: [],
      rechargeData: {
        recharge_way_id: "",
        currency_id: 1,
        amount: "",
        metadata: [],
        accept_terms: false,
      },
      checkoutData: null,
      errors: [],
    };
  },
  components: { SectionPreloader },
  mixins: [global],
  beforeMount() {},
  mounted() {
    $("#sectionPreloader").fadeIn();

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/recharge-way/all-active")
      .then((response) => {
        //console.log(response.data.recharge_ways);
        this.recharge_ways = response.data.recharge_ways;

        $("#sectionPreloader").fadeOut();
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          $("#sectionPreloader").fadeOut();

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      });
  },
  methods: {
    recharge: function() {
      this.errors = [];

      if ($("button[type=submit]").hasClass("disabled")) {
        return false;
      }

      $("button[type=submit]").addClass("disabled");

      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/requests/recharge/store",
          this.rechargeData
        )
        .then((response) => {
          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.rechargeData = {
            recharge_way_id: "",
            currency_id: 1,
            amount: "",
            metadata: [],
            accept_terms: false,
          };

          this.checkoutData = null;

          this.redirectWithDelay("/recarga-saldo");

          $("button[type=submit]").removeClass("disabled");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            $("button[type=submit]").removeClass("disabled");

            console.log(error.response.data.error);
          }
        });
    },
    changePaymentMethod: function(recharge_way_id) {
      this.errors = [];

      if (this.rechargeData.recharge_way_id != recharge_way_id) {
        $("input[type=radio][name=paymentMethod]").prop("checked", false);
        this.rechargeData.recharge_way_id = recharge_way_id;
        let index = this.recharge_ways.findIndex(
          (x) => x.id == recharge_way_id
        );
        this.rechargeData.metadata = [];
        this.recharge_ways[index].metadata.forEach((m) => {
          let item = {
            metadata_id: m.id,
            name: m.name,
            label: m.label,
            value: "",
          };
          this.rechargeData.metadata.push(item);
        });

        this.processPayment();
      }
    },
    processPayment: function() {
      this.errors = [];

      if (this.rechargeData.recharge_way_id && this.rechargeData.amount) {
        let index = this.recharge_ways.findIndex(
          (x) => x.id == this.rechargeData.recharge_way_id
        );

        const recharge_commission = {
          recharge_way_id: this.rechargeData.recharge_way_id,
          commission_type_id: this.recharge_ways[index].commission_type_id,
          amount: this.rechargeData.amount,
        };

        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/v1/requests/recharge/commissions",
            recharge_commission
          )
          .then((response) => {
            this.checkoutData = response.data.commissions;
            //console.log(response.data.message);
          })
          .catch((error) => {
            this.errors = [];

            this.checkoutData = null;

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              EventBus.$emit("showErrors", {
                msg: error.response.data.error,
              });

              console.log(error.response.data.error);
            }
          });
      }
    },
  },
};
</script>
