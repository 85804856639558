<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">Perfil de Empresa</h2>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- Profile Tab Content -->
        <form @submit.prevent="update" method="POST">
          <div class="card">
            <div class="card-body">
              <!-- Basic Information -->
              <h4 class="card-title">Datos de la cuenta</h4>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input
                      v-model="companyData.user.names"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Apellido</label>
                    <input
                      v-model="companyData.user.surnames"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Usuario</label>
                    <input
                      v-model="companyData.user.username"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-model="companyData.user.email"
                      type="email"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- /Basic Information -->

              <!-- Company Details -->
              <h4 class="card-title mt-3">Detalles de la empresa</h4>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Razón Social</label>
                    <input
                      v-model="companyData.social_reason"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Número de Identificación Fiscal</label>
                    <input
                      v-model="companyData.nif"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Teléfono de la empresa</label>
                    <input
                      v-model="companyData.phone"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.phone" class="text-danger">{{
                      errors.phone[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Fecha de creación
                      <span class="text-danger">*</span>
                    </label>
                    <v-menu
                        ref="menuDatePicker"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="companyCreationDateFormatted"
                            v-bind="attrs"
                            v-on="on"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            @click:clear="companyData.creation_date=null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          ref="creationDatePicker"
                          v-model="companyData.creation_date"
                          :active-picker.sync="activePicker"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          min="1900-01-01"
                          locale="es-ES"
                          :landscape="!$vuetify.breakpoint.mobile"
                          @change="saveMenuPickerDate"
                      ></v-date-picker>
                    </v-menu>
                    <small v-if="errors.creation_date" class="text-danger">{{
                        errors.creation_date[0]
                      }}</small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Dirección</label>
                    <input
                      v-model="companyData.address.address"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.address" class="text-danger">{{
                      errors.address[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Ciudad</label>
                    <input
                      v-model="companyData.address.city"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.city" class="text-danger">{{
                      errors.city[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Estado / Provincia</label>
                    <input
                      v-model="companyData.address.state"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.state" class="text-danger">{{
                      errors.state[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">País</label>
                    <v-autocomplete
                      class="form-control"
                      :items="countries"
                      flat
                      hide-details
                      hide-no-data
                      solo-inverted
                      item-text="name"
                      item-value="id"
                      v-model="companyData.address.country_id"
                    ></v-autocomplete>
                    <small v-if="errors.country_id" class="text-danger">{{
                      errors.country_id[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Código Postal</label>
                    <input
                      v-model="companyData.address.zip"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.zip" class="text-danger">{{
                      errors.zip[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <!-- /Company Details -->

              <!-- Social Network -->
              <h4 class="card-title mt-3">Redes Sociales</h4>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Página web</label>
                    <input
                      v-model="companyData.web_site"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.web_site" class="text-danger">{{
                      errors.web_site[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >https://www.ejemplo.com</small
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Facebook</label>
                    <input
                      v-model="companyData.facebook"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.facebook" class="text-danger">{{
                      errors.facebook[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >Cuenta de Facebook</small
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Instagram</label>
                    <input
                      v-model="companyData.instagram"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.instagram" class="text-danger">{{
                      errors.instagram[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >Cuenta de Instagram</small
                    >
                  </div>
                </div>
              </div>
              <!-- /Contact Details -->

              <div class="submit-section submit-btn-bottom text-right mt-4">
                <button type="submit" class="btn btn-primary btn-lg">
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- /Profile Tab Content -->
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import date from '../../helpers/date';

export default {
  mixins: [global, date],

  data() {
    return {
      companyData: {
        social_reason: "",
        nif: "",
        phone: "",
        web_site: "",
        facebook: "",
        instagram: "",
        user: {
          names: "",
          surnames: "",
          username: "",
          email: "",
          password: "",
          password_confirmation: "",
        },
        address: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country_id: "",
        },
        document: "",
        accept_terms: false,
        creation_date: null,
      },
      menu: false,
      activePicker: 'YEAR',
      countries: [],
      errors: [],
    };
  },

  beforeMount() {
    if (!this.curUser.roles.find((o) => o.name === "company")) {
      this.$router.push("/monedero");
      return;
    }

    this.getCountries();

    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/api/v1/profiles/company/get/" +
          this.curUser.company.id
      )
      .then((response) => {
        //console.log(response.data.company);
        this.companyData = response.data.company;
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      });
  },

  mounted() {},

  methods: {
    update: function() {
      this.errors = [];
      $("#preloader").fadeIn();

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/company/update/" +
            this.curUser.company.id,
          this.companyData
        )
        .then((response) => {
          //console.log(response.data.company);
          this.companyData = response.data.company;

          $("#preloader").fadeOut();

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }

          $("#preloader").fadeOut();
        });
    },
  },

  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.creationDatePicker.activePicker = 'YEAR'));
    },
  },
};
</script>
