<template>
  <!-- Header -->
  <div class="header">
    <div class="header-content">
      <nav class="navbar navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="header-left"></div>
          <ul class="navbar-nav header-right">
            <li v-if="exchangeRate != null" class="nav-item">
              <div class="d-none d-sm-flex weather-detail">
                <span><i class="las la-money-bill-alt"></i>{{exchangeRate.currency_from.acronym}}</span>
                = {{exchangeRate.value}} {{exchangeRate.currency_to.acronym}}
              </div>
              <div class="d-flex d-sm-none">
                1 {{exchangeRate.currency_from.acronym}} = {{exchangeRate.value}} {{exchangeRate.currency_to.acronym}}
              </div>
            </li>
            <!--
            <li class="nav-item">
              <div class="d-flex">
                <a href="javascript:void(0)" class="text-black"
                  ><i class="fa fa-qrcode fs-20"></i
                ></a>
              </div>
            </li>
            -->
            <notification></notification>

            <li class="nav-item dropdown header-profile">
              <a
                class="nav-link"
                href="javascript:void(0)"
                role="button"
                data-toggle="dropdown"
              >
                <div class="header-info">
                  <span class="text-black">
                    Hola,
                    <strong class="text-capitalize">{{
                      curUser.username
                    }}</strong>
                  </span>
                </div>
                <img v-if="curUser.person && curUser.person.avatar" :src="curUser.person.avatar" width="20" alt />
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link
                  :to="
                    curUser && curUser.roles[0].name == 'company'
                      ? '/perfil-empresa'
                      : '/perfil-usuario'
                  "
                  class="dropdown-item ai-icon"
                >
                  <svg
                    id="icon-user1"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-primary"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                  <span class="ml-2">Perfil</span>
                </router-link>
                <router-link class="dropdown-item ai-icon" to="/contrasena">
                  <i class="fa fa-lock"></i>
                  <span class="ml-2">Contraseña</span>
                </router-link>
                <a
                  @click="logout"
                  href="javascript:void(0)"
                  class="dropdown-item ai-icon"
                >
                  <svg
                    id="icon-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-danger"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1="21" y1="12" x2="9" y2="12" />
                  </svg>
                  <span class="ml-2">Salir</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <!-- /Header -->
</template>

<style src="@/assets/scss/header.scss" lang="scss" scoped></style>

<script>
import global from "@/helpers/global";
import Notification from "@/components/Notification/Notification";

export default {
  components: {
    Notification
  },
  data() {
    return {
      exchangeRate: null,
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    let exchange_rate = {
      date: this.formatDate(new Date().toISOString().slice(0, 10)),
      currency_from_acronym: "USD",
      currency_to_acronym: "VES",
    };

    axios
      .post(
        process.env.VUE_APP_API_URL +
          "/api/v1/exchange-rate/search-by-currencies",
        exchange_rate
      )
      .then((response) => {
        this.exchangeRate = response.data.exchangeRate;
        //console.log(response.data.exchangeRate);
      })
      .catch((error) => {
        this.errors = [];

        this.exchangeRate = null;

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          console.log(error.response.data.error);
        }
      });
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>
