<template>
  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-11 mx-auto">
          <!-- Register Tab Content -->
          <div class="account-content">
            <div class="row">
              <div class="col-md-12 col-lg-7">
                <form @submit.prevent="register" method="POST">
                  <div class="row">
                    <div class="col-md-10 my-5">
                      <h2>Registro Personal</h2>
                      <p>
                        Por favor, rellene el siguiente formulario para
                        registrarse.
                      </p>
                    </div>
                  </div>
                  <!-- Basic Information -->
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Datos de Usuario</h4>
                      <div class="row form-row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Usuario
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-lower-case
                                v-model="userData.user.username"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.username" class="text-danger">{{
                                errors.username[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Email
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-lower-case
                                v-model="userData.user.email"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.email" class="text-danger">{{
                                errors.email[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Contraseña
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-model="userData.user.password"
                                type="password"
                                class="form-control"
                            />
                            <small v-if="errors.password" class="text-danger">{{
                                errors.password[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Confirmar Contraseña
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-model="userData.user.password_confirmation"
                                type="password"
                                class="form-control"
                            />
                            <small
                                v-if="errors.password_confirmation"
                                class="text-danger"
                            >{{ errors.password_confirmation[0] }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Basic Information -->

                  <!-- About Me -->
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Información Personal</h4>
                      <div class="row form-row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Nombre
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-model="userData.user.names"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.names" class="text-danger">{{
                                errors.names[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Apellido
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-model="userData.user.surnames"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.surnames" class="text-danger">{{
                                errors.surnames[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Número de identificación
                              <span class="text-danger">*</span>
                            </label>
                            <input
                                v-model="userData.document"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.document" class="text-danger">{{
                                errors.document[0]
                              }}</small>
                            <small v-else class="form-text text-muted"
                            >Cédula/Pasaporte</small
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Teléfono</label>
                            <span class="text-danger">*</span>
                            <input
                                v-model="userData.phone"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.phone" class="text-danger">{{
                                errors.phone[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Fecha de nacimiento
                              <span class="text-danger">*</span>
                            </label>
                            <v-menu
                                ref="menuDatePicker"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="personBirthdayDateFormatted"
                                    v-bind="attrs"
                                    v-on="on"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    @click:clear="userData.birthday_date=null"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  ref="birthdayPicker"
                                  v-model="userData.birthday_date"
                                  :active-picker.sync="activePicker"
                                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                  min="1900-01-01"
                                  locale="es-ES"
                                  :landscape="!$vuetify.breakpoint.mobile"
                                  @change="saveMenuPickerDate"
                              ></v-date-picker>
                            </v-menu>
                            <small v-if="errors.birthday_date" class="text-danger">{{
                                errors.birthday_date[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Ocupación</label>
                            <span class="text-danger">*</span>
                            <v-autocomplete
                                class="form-control"
                                :items="occupations"
                                flat
                                hide-details
                                hide-no-data
                                solo-inverted
                                item-text="display_name"
                                item-value="id"
                                v-model="userData.occupation_id"
                            ></v-autocomplete>
                            <small
                                v-if="errors.occupation_id"
                                class="text-danger"
                            >{{ errors.occupation_id[0] }}</small
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Foto de Documento de Identidad
                              <span class="text-danger">*</span>
                            </label>
                            <div class="change-avatar">
                              <div class="profile-img">
                                <img
                                    v-if="userData.document_image"
                                    :src="userData.document_image"
                                    alt="User Image"
                                />
                                <img
                                    v-else
                                    src="@/assets/img/document.jpeg"
                                    alt="User Image"
                                />
                              </div>
                              <div class="upload-img">
                                <div class="change-photo-btn">
                                  <span>
                                    <i class="fa fa-upload"></i> Subir Foto
                                  </span>
                                  <v-file-input
                                    ref="fileInputDocumentImage"
                                    @change="uploadDocumentImage"
                                    class="upload"
                                    dense
                                    show-size
                                    small-chips
                                    :accept="this.allowedMimeTypesAsString"
                                    :rules="fileRules"
                                    label="Subir Foto"
                                  ></v-file-input>
                                </div>
                                <small class="form-text text-muted"
                                >Permitido JPG o PNG.</small
                                >
                              </div>
                            </div>
                            <small
                                v-if="errors.document_image"
                                class="text-danger"
                            >{{ errors.document_image[0] }}</small
                            >
                            <small v-else class="form-text text-muted"
                            >Se solicita documento para validar los datos
                              escritos en el formulario</small
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              Foto de Perfil
                              <span class="text-danger">*</span>
                            </label>
                            <div class="change-avatar">
                              <div class="profile-img">
                                <img
                                    v-if="userData.avatar"
                                    :src="userData.avatar"
                                    alt="User Image"
                                />
                                <img
                                    v-else
                                    src="@/assets/img/profile.jpeg"
                                    alt="User Image"
                                />
                              </div>
                              <div class="upload-img">
                                <div class="change-photo-btn">
                                  <span>
                                    <i class="fa fa-upload"></i> Subir Foto
                                  </span>
                                  <v-file-input
                                      ref="fileInputAvatar"
                                      @change="uploadAvatarImage"
                                      class="upload"
                                      dense
                                      show-size
                                      small-chips
                                      :accept="this.allowedMimeTypesAsString"
                                      :rules="fileRules"
                                      label="Subir Foto"
                                  ></v-file-input>
                                </div>
                                <small class="form-text text-muted"
                                >Permitido JPG o PNG.</small
                                >
                              </div>
                            </div>
                            <small v-if="errors.avatar" class="text-danger">{{
                                errors.avatar[0]
                              }}</small>
                            <small v-else class="form-text text-muted"
                            >Se solicita para comparar con el documento de
                              identidad</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /About Me -->

                  <!-- Contact Details -->
                  <div class="card contact-card">
                    <div class="card-body">
                      <h4 class="card-title">Datos de contacto</h4>
                      <div class="row form-row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Dirección</label>
                            <span class="text-danger">*</span>
                            <input
                                v-model="userData.address.address"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.address" class="text-danger">{{
                                errors.address[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Ciudad</label>
                            <span class="text-danger">*</span>
                            <input
                                v-model="userData.address.city"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.city" class="text-danger">{{
                                errors.city[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label"
                            >Estado / Provincia</label
                            >
                            <span class="text-danger">*</span>
                            <input
                                v-model="userData.address.state"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.state" class="text-danger">{{
                                errors.state[0]
                              }}</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">País</label>
                            <span class="text-danger">*</span>
                            <v-autocomplete
                                class="form-control"
                                :items="countries"
                                flat
                                hide-details
                                hide-no-data
                                solo-inverted
                                item-text="name"
                                item-value="id"
                                v-model="userData.address.country_id"
                            ></v-autocomplete>
                            <small
                                v-if="errors.country_id"
                                class="text-danger"
                            >{{ errors.country_id[0] }}</small
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Código Postal</label>
                            <span class="text-danger">*</span>
                            <input
                                v-model="userData.address.zip"
                                type="text"
                                class="form-control"
                            />
                            <small v-if="errors.zip" class="text-danger">{{
                                errors.zip[0]
                              }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Contact Details -->

                  <div class="form-group">
                    <div class="filter-widget">
                      <label class="custom_check">
                        <input
                            v-model="userData.accept_terms"
                            type="checkbox"
                            name="terms"
                        />
                        <span class="checkmark"></span> Términos y Condiciones.
                        Haga clic
                        <a
                            href="javascript:void();"
                            data-toggle="modal"
                            data-target="#termsModal"
                        >aquí</a
                        >
                        para ver.
                      </label>
                      <small
                          v-if="errors.accept_terms"
                          class="d-block text-danger ml-5 pl-3"
                      >{{ errors.accept_terms[0] }}</small
                      >
                    </div>
                  </div>

                  <div class="submit-section submit-btn-bottom">
                    <button type="submit" class="btn btn-primary">
                      Registrarse
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-md-12 col-lg-5">
                <v-img
                    alt="Descripción"
                    class="img-fluid"
                    :src="bannerActive.image"
                ></v-img>
              </div>
            </div>
          </div>
          <!-- /Register Tab Content -->
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
        class="modal fade"
        id="termsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="termsModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header pt-3">
            <h1 class="">Términos y Condiciones</h1>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-0">
            <section id="terminos" class="divider">
              <div class="container">
                <div class="row">
                  <p>
                    Este acuerdo de usuario será efectivo para todos los
                    usuarios a partir del 09 de agosto de 2020.
                  </p>
                  <p>¡Bienvenido a Pagos Undolar!</p>
                  <p>
                    Vestibulum tortor diam, tempus sed dui id, maximus tristique
                    metus. Proin et ligula eget neque tristique ullamcorper eget
                    sed turpis. Interdum et malesuada fames ac ante ipsum primis
                    in faucibus. Aenean a lobortis ante, vel tincidunt risus. In
                    eget auctor orci. Donec tincidunt mauris ut tellus mollis,
                    at mattis felis convallis.Este es un Acuerdo de usuario
                    entre usted (también denominado en el presente como
                    "Cliente" o "Usuario") y PAGOS UNDOLAR LLC. Este Acuerdo de
                    usuario ("Acuerdo") rige su uso de los servicios
                    proporcionados por PAGOS UNDOLAR LLC descritos a
                    continuación. Al registrarse para usar una cuenta a través
                    de https//:pagosundolar.com, usted acepta que ha leído,
                    comprende y acepta todos los términos y condiciones
                    contenidos en este Acuerdo,
                  </p>
                  <h2 class="w-100 mb-4">Parte 1: Uso General</h2>
                  <h3 class="w-100 mb-4">1.Configuración de la cuenta</h3>
                  <p>
                    <span class="font-weight-bold">1.1. Elegibilidad.</span>
                    Para ser elegible para utilizar los servicios de Pagos
                    Undolar, debe tener al menos 18 años.
                  </p>
                  <p>
                    <span class="font-weight-bold">1.2. Condiciones.</span>
                    Podemos enmendar o modificar este Acuerdo en cualquier
                    momento publicando el acuerdo revisado en el sitio de Pagos
                    Undolar y / o proporcionándole una copia (un "Acuerdo
                    revisado"). El Acuerdo revisado entrará en vigencia a partir
                    del momento en que se publique, pero no se aplicará
                    retroactivamente. Su uso continuado de los Servicios después
                    de la publicación de un Acuerdo revisado constituye su
                    aceptación de dicho Acuerdo revisado. Si no está de acuerdo
                    con dicha modificación, su único y exclusivo recurso es
                    terminar su uso de los Servicios y cerrar su cuenta.
                  </p>
                  <p>
                    <span class="font-weight-bold"
                    >1.3. Registro de cuenta.</span
                    >
                    Debe registrarse para obtener una cuenta de Pagos Undolar
                    para utilizar los servicios de Pagos Undolar. Al utilizar
                    una cuenta de Pagos Undolar, usted acepta y declara que
                    utilizará Pagos Undolar solo para usted, y no en nombre de
                    un tercero. Usted es totalmente responsable de toda la
                    actividad que se produzca en su cuenta de Pagos Undolar.
                    Podemos, a nuestra entera discreción, negarnos a abrir una
                    Cuenta de Pagos Undolar, o limitar el número de Cuentas de
                    Pagos Undolar que puede mantener o suspender o cancelar
                    cualquier Cuenta de Pagos Undolar.
                  </p>
                  <p>
                    <span class="font-weight-bold"
                    >1.4. Verificación de identidad.</span
                    >
                    Durante el registro de su cuenta de Pagos Undolar, acepta
                    proporcionarnos la información que solicitamos con el fin de
                    verificar la identidad y la detección de lavado de dinero,
                    financiamiento del terrorismo, fraude o cualquier otro
                    delito financiero y nos permite mantener un registro de tal
                    información. Deberá completar ciertos procedimientos de
                    verificación antes de que se le permita utilizar los
                    servicios de Pagos Undolar. Su acceso a uno o más Servicios
                    de Pagos Undolar y los límites que se aplican a su uso de
                    los Servicios de Pagos Undolar pueden verse alterados como
                    resultado de la información recopilada sobre usted de forma
                    continua. La información que solicitamos puede incluir
                    cierta información personal, que incluye, entre otros, su
                    nombre, dirección, número de teléfono, dirección de correo
                    electrónico, fecha de nacimiento, número de identificación
                    del contribuyente, una identificación del gobierno e
                    información sobre su cuenta bancaria (como como el nombre
                    del banco, el tipo de cuenta, el número de ruta y el número
                    de cuenta) y, en algunos casos (cuando lo permita la ley),
                    categorías especiales de datos personales, como su
                    información biométrica. Al proporcionarnos esta o cualquier
                    otra información que pueda ser necesaria, usted confirma que
                    la información es precisa y auténtica. Acepta mantenernos
                    actualizados si cambia la información que nos proporciona.
                    Usted nos autoriza a realizar las consultas, ya sea
                    directamente o/a través de terceros, que consideremos
                    necesarias para verificar su identidad o protegerlo a usted
                    y / o a nosotros contra el fraude u otro delito financiero,
                    y para tomar las medidas que consideremos razonablemente
                    necesarias en función de los resultados de tal consultas.
                    Cuando llevamos a cabo estas consultas, usted reconoce y
                    acepta que su información personal puede ser divulgada a
                    agencias de referencia crediticia y prevención de fraude o
                    delitos financieros y que estas agencias pueden responder a
                    nuestras consultas en su totalidad. Esta es solo una
                    verificación de identidad y no debería tener ningún efecto
                    adverso en su calificación crediticia. Además, autoriza a su
                    operador inalámbrico (AT&T, Sprint, T-Mobile, US Cellular,
                    Verizon o cualquier otro operador inalámbrico de marca) a
                    usar su número de teléfono móvil, nombre, dirección, correo
                    electrónico, estado de la red, tipo de cliente, función del
                    cliente, facturación. tipo, identificadores de dispositivos
                    móviles (IMSI e IMEI) y otros detalles del estado del
                    suscriptor, si están disponibles, únicamente para permitir
                    la verificación de su identidad y para comparar la
                    información que ha proporcionado a Pagos Undolar con la
                    información del perfil de su cuenta de operador inalámbrico
                    durante la duración del negocio relación.
                  </p>
                  <p>
                    <span class="font-weight-bold">1.5. Acceso.</span> Para
                    acceder a los Servicios de Pagos Undolar, debe tener el
                    equipo necesario (como un teléfono inteligente o computadora
                    portátil) y las suscripciones de servicios de
                    telecomunicaciones asociadas para acceder a Internet. Se
                    puede acceder a los servicios de Pagos Undolar directamente
                    utilizando el sitio de Pagos Undolar (www.pagosundolar.com).
                    El acceso a los Servicios de Pagos Undolar puede degradarse
                    o no estar disponible durante momentos de volatilidad o
                    volumen significativo. Esto podría resultar en la
                    imposibilidad de comprar o vender durante períodos de tiempo
                    y también puede dar lugar a demoras en el tiempo de
                    respuesta del soporte. Aunque nos esforzamos por brindarle
                    un excelente servicio, no representamos que el Sitio de
                    Pagos Undolar estarán disponibles sin interrupción y no
                    garantizamos que ninguna orden será ejecutada, aceptada,
                    registrada o permanecerá abierta. Pagos Undolar no será
                    responsable de ninguna pérdida que resulte o surja de
                    retrasos en las transacciones.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
  <!-- /Page Content -->
</template>

<style src="@/assets/scss/frontend/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import date from "@/helpers/date";
import image from "@/helpers/image";

export default {
  data: () => ({
    focused: false,
    userData: {
      document: "",
      phone: "",
      user: {
        names: "",
        surnames: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      address: {
        address: "",
        city: "",
        zip: "",
        country_id: "",
      },
      document_image: "",
      avatar: "",
      accept_terms: false,
      birthday_date: null,
      occupation_id: null,
    },
    fileRules: [],
    menu: false,
    activePicker: 'YEAR',
    countries: [],
    occupations: [],
    errors: [],
  }),
  mixins: [global, date, image],

  beforeMount() {
    this.getCountries();
    this.getActiveBanner();
    this.getOccupations();
  },
  mounted() {
    this.fileRules = [
      this.maxSizeRule(),
      this.mimeTypeRule(),
    ];

    if (this.checkIfLoggedIn()) this.$router.push({ name: 'wallet' });
  },
  methods: {
    uploadDocumentImage(file) {
      this.clearDocumentImageErrors();

      if (file == null ||
          !file ||
          file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
          !this.checkMimeTypeRule(file.type)
      ) {
        this.userData.document_image = "";
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userData.document_image = reader.result;
      };
    },
    uploadAvatarImage(file) {
      this.clearAvatarErrors();

      if (file == null ||
          !file ||
          file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
          !this.checkMimeTypeRule(file.type)
      ) {
        this.userData.avatar = "";
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userData.avatar = reader.result;
      };
    },
    register: function () {
      $("#preloader").fadeIn();

      if (!this.$refs.fileInputDocumentImage.validate() || !this.$refs.fileInputAvatar.validate()) {
        this.errors = [];

        $("#preloader").fadeOut();

        return;
      }

      axios
          .post(
              process.env.VUE_APP_API_URL + "/api/v1/register/person",
              this.userData
          )
          .then(() => {
            this.$router.push("/usuario-creado");
            $("#preloader").fadeOut();
          })
          .catch((error) => {
            this.errors = [];

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              EventBus.$emit("showErrors", {
                msg: error.response.data.error,
              });

              console.log(error.response.data.error);
            }

            $("#preloader").fadeOut();
          });
    },
    clearDocumentImageErrors() {
      this.errors.document_image = [];
    },
    clearAvatarErrors() {
      this.errors.avatar = [];
    },
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'));
    },
  },
};
</script>
