<template>
  <section :class="{'bg-dark' : darkBackground}" class="section faqs-section py-5">
    <div class="container-fluid">
      <div :class="[!darkBackground ? 'page-titles d-flex justify-content-between align-items-center' : 'mb-10 mt-2 text-uppercase']">
        <a id="faq"></a>
        <h2 :class="[
            whiteHeaderText ? 'text-white w-100' : 'text-black font-w600 w-100',
            centerHeaderText ? 'text-center' : '',
        ]" v-t="'home.faq'"></h2>
      </div>
      <div class="row">
        <div :class="[containerBodyContent ? 'container' : 'col-12']">
          <div class="card">
            <div class="card-body">
              <faqs-list></faqs-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FaqsList from './FaqsList';

export default {
  name: "FaqsIndex",
  components: { FaqsList },
  props: {
    darkBackground: {
      type: Boolean,
      default: false,
      require: false
    },
    whiteHeaderText: {
      type: Boolean,
      default: false,
      require: false
    },
    centerHeaderText: {
      type: Boolean,
      default: false,
      require: false
    },
    containerBodyContent: {
      type: Boolean,
      default: false,
      require: false,
    }
  }
};
</script>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>
