import moment from 'moment';

export default {
    methods: {
        formatDate(date, format = 'D-M-Y') {
            return moment(date).format(format);
        },

        formatDateWithLocale(date, format = 'D-M-Y', locale = 'es-ES') {
            return moment(date).locale(locale).format(format);
        },

        saveMenuPickerDate(date) {
            this.$refs.menuDatePicker.save(date);
        }
    },

    computed: {
        companyCreationDateFormatted() {
            const creationDate = _.get(this, 'companyData.creation_date', null);

            return creationDate ? this.formatDateWithLocale(creationDate, 'DD MMMM YYYY') : '';
        },

        personBirthdayDateFormatted() {
            const birthdayDate = _.get(this, 'userData.birthday_date', null);

            return birthdayDate ? this.formatDateWithLocale(birthdayDate, 'DD MMMM YYYY') : '';
        }
    }
};
