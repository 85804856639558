<template>
  <div class="app-message">
    <div v-if="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
      <div class="alert-heading">
        <i class="fa fa-exclamation-circle mr-1"></i>
        Error
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-on:click="clearMessages"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="alert-content">
        <span
          v-for="(value, key) in errors"
          v-bind:key="key"
          class="invalid-feedback"
          role="alert"
        >{{ value }}</span>
      </div>
    </div>
    <div v-if="msg" class="alert alert-info alert-dismissible fade show" role="alert">
      <div class="alert-heading">
        <i class="fa fa-comment mr-1"></i>
        Información
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-on:click="clearMessages"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="alert-content">
        <span
          v-for="(value, key) in msg"
          v-bind:key="key"
          class="valid-feedback"
          role="alert"
        >{{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  data() {
    return {
      errors: null,
      msg: null
    };
  },
  created() {
    EventBus.$on("showErrors", errors => {
      const _self = this;
      _self.msg = null;
      _self.errors =
        errors.msg != undefined ? errors : { msg: "Ha ocurrido un error. Si el problema persiste contacte a soporte." };
      setTimeout(function() {
        _self.errors = null;
      }, 7000);
    });

    EventBus.$on("showInfo", msg => {
      const _self = this;
      _self.errors = null;
      _self.msg = msg;
      setTimeout(function() {
        _self.msg = null;
      }, 7000);
    });

    EventBus.$on("clearMessages", () => {
      this.clearMessages();
    });
  },
  methods: {
    clearMessages: function() {
      this.errors = null;
      this.msg = null;
    }
  }
};
</script>
