import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=009d8d82&scoped=true&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/frontend/content.scss?vue&type=style&index=0&id=009d8d82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009d8d82",
  null
  
)

export default component.exports