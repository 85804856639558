import 'datatable-sorting-datetime-moment';

export default {
  data() {
    return {
      dataTable: null,
      data: [],
      tableFields: [],
      withChildRow: false,
      childColumns: [],
      childDataFields: [],
      dataTableDOMIdentifierElement: null,
    };
  },
  methods: {
    initializeTable: function (table, custom_options = false) {
      let options = {
        pageLength: 25,
        order: [],
        bFilter: false,
        //bLengthChange: false,
        //info: false,
        processing: true,
        language: {
          info: "Mostrando _START_ a _END_ de _TOTAL_ Elementos",
          infoEmpty: "",
          infoFiltered: "Mostrando _START_ a _END_ de _MAX_ Elementos",
          emptyTable: "No hay datos para mostrar",
          sZeroRecords: "No hay datos para mostrar",
          lengthMenu: "Mostrar: _MENU_",
          search: "Buscar:",
          processing: "<span class='fa-stack fa-xs'><i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i></span>&emsp;&nbsp;&nbsp;&nbsp;Cargando ...",
          paginate: {
            first: "Primero",
            last: "Último",
            next: "Próximo",
            previous: "Anterior"
        },
        },
        columnDefs: [{
            responsivePriority: 1,
            targets: 0
          },
          {
            responsivePriority: 2,
            targets: -1
          },
          {
            targets: "no-sort",
            orderable: false
          }
        ],
        initComplete: function () {
          $(this).closest(".dataTables_wrapper").find(".dataTables_processing").css('display', 'block');
          $(this).addClass('table-loading');
          $(this).closest(".dataTables_wrapper").find("select").selectpicker();
        },
        drawCallback: function () {
          var pagination = $(this)
            .closest(".dataTables_wrapper")
            .find(".dataTables_paginate");

          pagination.toggle(this.api().page.info() != undefined && this.api().page.info().pages > 1);
        }
      };

      if (custom_options && typeof custom_options === 'object') {
        options = {
          ...options,
          ...custom_options
        };
      }

      if (this.withChildRow) {
        this.tableFields.unshift("");

        const columnShowChildRowAction = options.columnDefs.find((column) => column.className === 'details-control');

        if (!columnShowChildRowAction) {
          options.columnDefs.unshift({
            targets: 0,
            className: 'details-control',
            orderable: false,
            data: null,
            defaultContent: ''
          });
        }
      }

      $.fn.dataTable.moment('DD/MM/YYYY HH:mm');

      this.dataTableDOMIdentifierElement = table
      this.dataTable = $(this.dataTableDOMIdentifierElement).DataTable(options);

      return this.dataTable;
    },
    renderTable: function (dta = this.dataTable) {
      setTimeout(function () {
        const table_id = dta.table().node().id;
        $('#' + table_id).closest(".dataTables_wrapper").find(".dataTables_processing").remove();
        $('#' + table_id).removeClass('table-loading');

        $.fn.dataTable.moment('DD/MM/YYYY HH:mm');

        $('#' + table_id)
          .DataTable()
          .draw(false)
          .columns.adjust();
      }, 300);
    },
    createRow: function (obj, actions = true) {
      let row = [];

      let columns = this._clone(this.tableFields);

      if (columns !== null && Array.isArray(columns)) {
        columns.forEach(column => {
          const col_value = this.getColValue(obj, column);
          row.push(this.wrapText(col_value));
        });
      } else {
        Object.keys(obj).forEach(column => {
          const col_value = this.getColValue(obj, column);
          row.push(this.wrapText(col_value));
        });
      }

      if (actions) {
        if (typeof this.tableActionsByObject !== "undefined") {
          row.push(this.tableActionsByObject(obj));

          return row;
        }

        if (typeof this.tableActions !== "undefined")
          row.push(this.tableActions(obj.id));
        else
          row.push("");
      }

      return row;
    },
    generateTableWithData: function (data, actions = true, dta = this.dataTable) {
      dta.clear();

      data.forEach(obj => {
        let row = this.createRow(obj, actions);
        dta.row.add(row);
      });

      dta.draw();

      if (this.withChildRow) {
        this.data = data;
        this.generateOnClickChildRowEvent();
      }
    },
    generateTable: function (actions = true, dta = this.dataTable) {
      this.generateTableWithData(this.data, actions, dta);
    },
    updateRowTable: function (obj, actions = true, dta = this.dataTable) {
      dta.row(".selected").data(this.createRow(obj, actions)).draw(false);
    },
    getObjChilds: function (obj, property) {
      var value = [];
      if (Array.isArray(obj)) {
        obj.forEach(objColumn => {
          var v = _.get(objColumn, property[0]);
          if (property.length > 1) {
            var p1 = this._clone(property);
            p1.shift();
            v = this.getObjChilds(v, p1);

          }
          value.push(v);
        });
      } else {
        value = _.get(obj, property[0]);
        if (property.length > 1) {
          property.shift();
          value = this.getObjChilds(value, property);
        }
      }

      return value;
    },
    getColValue: function (obj, column) {
      let value = "";
      let callback = null;

      if (column.indexOf(':') > 0) {
        callback = column.split(':')[1];
        column = column.split(':')[0];
      }

      let col_name = column.split('.');
      let col_value = obj[col_name[0]];

      if (col_name.length > 1 && Array.isArray(col_value)) {
        col_name.shift();
        var childs = [...new Set(this.getObjChilds(col_value, col_name))];

        childs.forEach(v => {
          if (typeof v !== 'undefined' && v !== null) {
            if (callback !== null && callback != "") {
              v = this[callback](v);
            }
          } else {
            v = "";
          }
          value = value + ", " + v;
        });
        value = value.replace(', ', '');
      } else {
        value = _.get(obj, column);

        if (typeof value !== 'undefined' && value !== null) {
          if (callback !== null && callback != "") {
            value = this[callback](value);
          }
        } else {
          value = "";
        }
      }

      return value;
    },
    wrapText: function (v, length = 35) {
      if (typeof v === "string" && v.length > length) {
        return '<span class="wrap-text" title="' + v + '">' + v + "</span>";
      } else if (typeof v === "object") {
        for (const [key, value] of Object.entries(v)) {
          switch (key) {
            case 'html':
              return value;
            default:
              return (value.length > length) ? '<span class="wrap-text" title="' + value + '">' + value + "</span>" : value;
          }
        }
      }

      return v;
    },
    dateFormat: function (dateUnformatted) {
      const date = new Date(dateUnformatted);
      const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "numeric",
        day: "2-digit"
      });
      const [{
          value: month
        }, ,
        {
          value: day
        }, ,
        {
          value: year
        }
      ] = dateTimeFormat.formatToParts(date);

      return `${month}/${day}/${year}`;
    },
    _clone: function (obj) {
      if (typeof obj === "undefined") {
        return "";
      }
      return JSON.parse(JSON.stringify(obj));
    },
    filterColumn: function (search, column = 0, exact_match = true) {
      if (typeof search !== "undefined") {
        search = exact_match ? "^" + search + "$" : search;
        this.dataTable
          .column(column)
          .search(search, 1, 0)
          .draw();
      } else {
        this.dataTable
          .column(column)
          .search("", 0, 1)
          .draw();
      }
    },
    getFieldArrayFromObject: function (obj, key) {
      let parent_key = key.split('.')[0];

      return [
        ...new Set(
          _.map(obj, x => (x[parent_key] ? _.get(x, key) : "")).filter(function (el) {
            return el != "";
          })
        )
      ].sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    },
    getPropertyFromObject: function (obj, key) {
      var returnObj = [];

      if (!Array.isArray(obj)) {
        obj = [obj]
      }

      obj.forEach(_obj => {
        let obj_name = key.split('.');
        let obj_value = _obj[obj_name[0]];

        if (obj_name.length > 1 && Array.isArray(obj_value)) {
          obj_name.shift();
          var childs = [...new Set(this.getObjChilds(obj_value, obj_name))];

          childs.forEach(v => {
            returnObj.push(v);
          });
        } else {
          if (obj_name.length > 1) {
            obj_name.shift();
            obj_value = this.getObjChilds(obj_value, obj_name);
          }
          if (typeof obj_value === 'undefined') {
            obj_value = "";
          }
          returnObj.push(obj_value);
        }
      });

      return [...new Set(returnObj)];
    },
    generateOnClickChildRowEvent() {

      const component = this;

      this.getDOMElementDatatable.on("click", "td.details-control", function () {
        const tr = $(this).parents('tr');
        const row = component.dataTable.row(tr);

        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass('shown');
        } else {
          row.child(component.generateChildTable(component.data[row.index()])).show();
          tr.addClass('shown');
        }
      });
    },
    generateChildTable(dataRow) {

      let htmlTable = `<table class="table table-bordered responsive w-75 ml-lg-8 ml-xl-16">`

      let htmlHeadTable = '<thead>'
          this.childColumns.forEach(function (column) {
            htmlHeadTable += `<th class="th-child">${column}</th>`
          });
      htmlHeadTable += '</thead>';

      let htmlBodyTable = '<tbody><tr>'
      this.childDataFields.forEach(function (field) {
        htmlBodyTable += `<td class="td-child-data">${_.get(dataRow, field, '')}</td>`;
      });

      htmlBodyTable += '</tr></tbody>';

      htmlTable += `${htmlHeadTable}${htmlBodyTable}</table>`;

      return htmlTable;
    },
    generateDetailsAction(attrId, actions = null, title = 'Ver detalles', text = 'Detalles') {

      const htmlDetailsActionButton = `<a href="javascript:void(0);"
                    title="${title}"
                    attr_id="${attrId}"
                    class="btn btn-outline-info btn-md btn-rounded m-1 action-details">
            ${text}
          </a>`;

      if (actions && _.isString(actions)) {
        actions += htmlDetailsActionButton;
      }

      return htmlDetailsActionButton;
    }

  },
  computed: {
    getDOMElementDatatable() {
      return $(this.dataTableDOMIdentifierElement);
    },
  }
}
