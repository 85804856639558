<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">Cambiar Contraseña</h2>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- Password Tab Content -->
        <form @submit.prevent="change" method="POST">
          <div class="card">
            <div class="card-body">
              <!-- Form -->
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Contraseña actual</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="passData.old_password"
                    />
                    <small v-if="errors.old_password" class="text-danger">{{
                      errors.old_password[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Nueva Contraseña</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="passData.password"
                    />
                    <small v-if="errors.password" class="text-danger">{{
                      errors.password[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Repetir Contraseña</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="passData.password_confirmation"
                    />
                    <small v-if="errors.password_confirmation" class="text-danger">{{
                      errors.password_confirmation[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <!-- /Form -->

              <div class="submit-section submit-btn-bottom text-right mt-4">
                <button type="submit" class="btn btn-primary btn-lg">
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- /Password Tab Content -->
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      passData: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      errors: [],
    };
  },
  mixins: [global],
  mounted() {},
  methods: {
    change: function() {
      $("#preloader").fadeIn();

      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/v1/user/change-password",
          this.passData
        )
        .then((response) => {
          //console.log(response.data.person);

          $("#preloader").fadeOut();

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }

          $("#preloader").fadeOut();
        });
    },
  },
};
</script>
